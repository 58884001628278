import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { TooltipPosition } from '@angular/material';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { dateLessThan } from 'src/app/helpers/Validators/app-validators';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { BaseService } from 'src/app/helpers/common/base.service';

export interface DriversList {
  driver: string;
  vehicleNo: string;
  city: string;
  weight: string;
  numberOfRequest: string;
}
@Component({
  selector: 'app-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.scss']
})
export class DriversListComponent implements OnInit {
  drivers: any;
  response: any;
  filterByDate: FormGroup;
  countryAllSelected: boolean = true;
  countryid = 0;
  startDate = new Date();
  endDate = new Date();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  driversDataSource: MatTableDataSource<DriversList>;
  driversColumn: string[] = ['driver', 'vehicleNo', 'city', 'weight', 'numberOfRequest'];
  constructor(public globalMethodsService: GlobalMethodsService,
    public baseService: BaseService,
    public route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder, public validationMessages: ValidationMessagesService) {
    window.scroll(0, 0);
    this.startDate = this.globalMethodsService.setFromDate(this.startDate, -3);


    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {

      this.drivers = this.response.result;

    }
    else if (this.response.length > 0) {
      this.drivers = this.response
    }
    else { this.drivers = []; }
    this.driversDataSource = new MatTableDataSource(this.drivers);
  }

  ngOnInit() {

    this.filterByDate = this.formBuilder.group({
      'startDate': [new Date(this.startDate), Validators.compose([Validators.required])],
      'endDate': [this.endDate, Validators.compose([Validators.required])],
    }, { validator: dateLessThan('startDate', 'endDate') });
    this.filterByDate.get("endDate").valueChanges.subscribe(x => {
      setTimeout(() => {
        this.filterByDateSubmit();
      })
    });
    this.filterByDate.get("startDate").valueChanges.subscribe(x => {
      setTimeout(() => {
        this.filterByDateSubmit();
      })

    });
    this.driversDataSource.paginator = this.paginator;
    this.driversDataSource.sort = this.sort;

    if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country') {
      console.log("ddfsdfsf")
      this.countrySelectLoad();
      
    }

    
  }
  applyFilter(filterValue: string) {
    this.driversDataSource.filter = filterValue.trim().toLowerCase();

    if (this.driversDataSource.paginator) {
      this.driversDataSource.paginator.firstPage();
    }
  }
  createString(arr, key) {
    //debugger
    return arr.map(function (obj) {
      return "" + obj[key] + "";
    }).join(', ');
  }
  filterByDateSubmit() {
    //debugger
    if (this.filterByDate.value.startDate != null && this.filterByDate.value.endDate != null) {
      if (this.filterByDate.valid) {
        this.globalMethodsService.loader.startBackground();
        let startDate = this.globalMethodsService.setDate(this.filterByDate.value.startDate);
        let data = null;
        let endDate = this.globalMethodsService.setDate(this.filterByDate.value.endDate);


        //debugger
        data = {
          "startDate": startDate,
          "endDate": endDate,
          "countryId": this.countryid
        }
        if (this.countryid == 0) {
          delete data.countryId;
        }
        this.httpHelper.postWithAuthToken('Common/byDriver', data).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.length > 0) {
            this.drivers = this.response;
          }
          else { this.drivers = []; }
          this.driversDataSource = new MatTableDataSource(this.drivers);
          this.driversDataSource.paginator = this.paginator;
          this.driversDataSource.sort = this.sort;
        });
      }
      else {
        this.validationMessages.validateAllFormFields(this.filterByDate);
      }
    }
  }
  countrySelect(ev) {
    this.countryid = +ev.currentTarget.value;
    if (this.countryid != 0) {
      this.countryAllSelected = false;
      this.filterByDateSubmit();
      //  this.filterByDate();
    }
    else {
      this.countryAllSelected = true;
      this.filterByDateSubmit();
      //this.filterByDate();
    }
  }

  countrySelectLoad() {
    this.countryid = 1;
    if (this.countryid != 0) {

      this.countryAllSelected = false;
      //  this.filterByDate();
    }
    this.filterByDateSubmit();
  }

}
