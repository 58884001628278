import { FormGroup, FormControl } from '@angular/forms';
export function emailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}
export function passwordValidator(control: FormControl): { [key: string]: any } {
    var passwordRegexp = /^(?=(.*\d.*){1,})(?=(.*[a-zA-Z].*){5,}).{6,40}$/;
    if (control.value && !passwordRegexp.test(control.value)) {
        return { invalidPassword: true };
    }
}
export function nameValidator(control: FormControl): { [key: string]: any } {
    var nameRegexp = /^[^-\s][a-zA-Z_\s-]+$/;
    if (control.value && !nameRegexp.test(control.value)) {
        return { nameValidator: true };
    }
}
export function commentValidator(control: FormControl): { [key: string]: any } {
    var nameRegexp = /^[^\s].*$/;
    if (control.value && !nameRegexp.test(control.value)) {
        return { commentValidator: true };
    }
}
export function nameArValidator(control: FormControl): { [key: string]: any } {
    var nameArRegexp = /^[^a-zA-Z~`!@#$%^&*+{}|"?><']+[^a-zA-Z~`!@#$%^&*+{}|"?><']+$/;
    if (control.value && !nameArRegexp.test(control.value)) {
        return { nameArValidator: true };
    }
}
export function phoneNumberValidator(control: FormControl): { [key: string]: any } {
    var phoneNumberRegexp = /^(?:\+968|00968|\+973|00973|00974|\+974|00965|\+965)[0-9]*$/;
    if (control.value && !phoneNumberRegexp.test(parseArabic(control.value))) {
        return { phoneNumberValidator: true };
    }
}
  function parseArabic(str) {
    return String( str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function(d) {
        return d.charCodeAt(0) - 1632; // Convert Arabic numbers
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function(d) {
        return d.charCodeAt(0) - 1776; // Convert Persian numbers
    }) );
}
export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}
export function dateLessThan(from: string, to: string): { [key: string]: any } {
    return (group: FormGroup) => {
        let f = group.controls[from];
        let fromDate = f.value;
        let fromDateConverted = new Date(fromDate);

        let t = group.controls[to];
        let toDate = t.value;
        let toDateConverted = new Date(toDate);
        if (fromDateConverted > toDateConverted) {
            return t.setErrors({ dateLessThan: true });
        }
        else if (fromDateConverted < toDateConverted) {
            f.setErrors(null);
            t.setErrors(null);
        }
        else if (!fromDate && !toDate) {
            f.setErrors(null);
            t.setErrors(null);
        }
        else {
            return null;
        }
    }
}
export function dateLessThanDashboard(from: string, to: string): { [key: string]: any } {
    return (group: FormGroup) => {
        let f = group.controls[from];
        let fromDate = f.value ? f.value.year + '-' + f.value.month + '-' + f.value.day : f.value;
        let fromDateConverted = new Date(fromDate);

        let t = group.controls[to];
        let toDate = t.value ? t.value.year + '-' + t.value.month + '-' + t.value.day : f.value;
        let toDateConverted = new Date(toDate);
        if (fromDateConverted > toDateConverted) {
            return t.setErrors({ dateLessThan: true });
        }
        else if (fromDateConverted < toDateConverted) {
            f.setErrors(null);
            t.setErrors(null);
        }

    }
}

export function timeLessThan(time: string, date: string): { [key: string]: any } {
    return (group: FormGroup) => {
        var getTime = new Date();
        var currentTime = `${getTime.getHours()}:${getTime.getMinutes()}`;

        let f = group.controls[time];
        var convertedTime;
        let fromTime;
        if (f.value != null) {
            fromTime = f.value;
            convertedTime = `${fromTime.getHours()}:${fromTime.getMinutes()}`;
        }

        let t = group.controls[date];
        let toDate = t.value;
        let getDate = new Date(toDate);

        let currentDate = new Date();
        if (currentDate < getDate) {
            return null;
        }
        else if ((currentDate.toLocaleDateString().slice(0, 10)) == (getDate.toLocaleDateString().slice(0, 10))) {
            if (getTime > fromTime) {
                return t.setErrors({ timeLessThanValidator: true });
            }
            else{
                t.setErrors(null);
            }
        }

        else {
            return null;
        }
    }
}
