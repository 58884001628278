import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { MatTableDataSource, TooltipPosition } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';

declare var google: any;

export interface UserData {
  id: string,
  weight: number,
  createdAt: Date,
  'chairty.nameAr': string
  creator:string
}

@Component({
  selector: 'app-donation-box-weight-list.component',
  templateUrl: './donation-box-weight-list.component.html',
  styleUrls: ['./donation-box-weight-list.component.scss']
})
export class DonationBoxWeightListComponent implements OnInit {
  response: any;
  showMap: boolean = true;
  donationBoxes: any;
  charityAllSelected: any;
  monthsAllSelected: any;
  yearsAllSelected: any;
  charities: any;
  selectedCharityId: Number = 0;
  selectedMonth: Number = 0;
  selectedYear: Number = 0;
  displayedColumns: string[] = ['id', 'charity.nameAr', 'weight', 'createdAt','creator'];
  dataSource: MatTableDataSource<UserData>;
  map: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('map', {static: false}) mapElement:any;

  markers = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(public globalMethodsService: GlobalMethodsService,
    globalService: GlobalMethodsService,
    public appComp: AppComponent,
    private route: ActivatedRoute,
    public httpHelper: HttpClientService) {
    window.scroll(0, 0);

    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {

      this.donationBoxes = this.response.result;
      this.dataSource = new MatTableDataSource(this.donationBoxes);
      if(this.globalMethodsService.userObj.role == "charityUser"){
        this.selectedCharityId = this.donationBoxes[0].charityId;
      }

    }
  }

  ngOnInit() {
    // get list of active charities in Oman
    this.getCharities();
    this.setSortingDataAccessorForNestedObjects();
    this.globalMethodsService.loader.stopAll();
  }

  ngAfterViewInit(): void {
    this.appComp.showHeader = true;
  }

  weightSum() {
    let sum: number = 0;
    if (this.dataSource)
      for (let row of this.dataSource.data) {
         sum += row['weight'];
      }
    return sum;
  }

  /**
   * Allow nested objects to be accessed for sorting in the table.
   */
  setSortingDataAccessorForNestedObjects() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      // if the property includes (.), means it's nested field in JSON object
      if (property.includes('.')) {
        const accessor = property.split('.');
        let value: any = item;
        accessor.forEach((a) => {
          value = value[a];
        });

        // return the value of the nested object
        return value;
      }

      // if it is not nested field, then return the value as-is
      return item[property];
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCharities() {
    let data = {
      countryCode: 'OMN'
    };

    this.httpHelper.put('Common/getDonationBoxesCharities', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
      }
      else {
        this.charities = [];
      }
    });
  }

  charityChange(ev) {
    this.selectedCharityId = +ev.currentTarget.value;
    if (this.selectedCharityId != 0 && this.selectedMonth == 0 && this.selectedYear == 0) {
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecordsByCharityId?charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });

    } else if (this.selectedCharityId == 0 && this.selectedMonth == 0 && this.selectedYear == 0) { // if all chariites selected
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecords').then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });
    }else{
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecordsByMonthYear?month=' + this.selectedMonth + '&year=' + this.selectedYear+ '&charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });
    }


  }

  monthChange(ev){
    this.selectedMonth = +ev.currentTarget.value;
    if (this.selectedMonth > 0 && this.selectedYear > 0 && this.selectedCharityId == 0) {
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecordsByMonthYear?month=' + this.selectedMonth + '&year=' + this.selectedYear+ '&charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });

    }else if (this.selectedMonth > 0 && this.selectedYear > 0 && this.selectedCharityId > 0){
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecordsByMonthYear?month=' + this.selectedMonth + '&year=' + this.selectedYear + '&charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });
    }
  }

  yearChange(ev){
    this.selectedYear = +ev.currentTarget.value;
    if (this.selectedMonth > 0 && this.selectedYear > 0 && this.selectedCharityId == 0) {
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecordsByMonthYear?month=' + this.selectedMonth + '&year=' + this.selectedYear + '&charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });

    }else if (this.selectedMonth > 0 && this.selectedYear > 0 && this.selectedCharityId > 0){
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecordsByMonthYear?month=' + this.selectedMonth + '&year=' + this.selectedYear + '&charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });
    }
  }

  /**
   * Load dontation boxes data in the table.
   */
  loadDonationBoxesInTable(){
    // if there is data, load them immediately
    if (this.response.result.length > 0) {
      this.donationBoxes = this.response.result;
      this.dataSource = new MatTableDataSource(this.donationBoxes);
      this.setSortingDataAccessorForNestedObjects();

      
    } else {
      this.donationBoxes = [];
      this.showMap = false;
      this.dataSource = new MatTableDataSource(this.donationBoxes);
    }

  }

}
