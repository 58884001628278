import { Component, OnInit } from '@angular/core';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { nameArValidator } from 'src/app/helpers/Validators/app-validators';

@Component({
  selector: 'app-add-notifications',
  templateUrl: './add-notifications.component.html',
  styleUrls: ['./add-notifications.component.scss']
})
export class AddNotificationsComponent implements OnInit {
  addNotificationForm: FormGroup;
  response: any;
  countryAllSelected:boolean=true;
  constructor(public globalMethodsService: GlobalMethodsService,
    public route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder, public router: Router, public validationMessages: ValidationMessagesService, ) { window.scroll(0, 0); }

  ngOnInit() {
    this.addNotificationForm = this.formBuilder.group({
      'En': ['', Validators.compose([Validators.required])],
      'Ar': ['', Validators.compose([Validators.required, nameArValidator])],
      'countryId': [0, Validators.compose([Validators.required])]
    });
  }
  addNotificationSubmit() {
    if (this.addNotificationForm.valid) {
      this.addNotificationForm.value.countryId=+this.addNotificationForm.value.countryId;
      this.globalMethodsService.loader.startBackground();
      this.httpHelper.put('Notification/add', this.addNotificationForm.value).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.router.navigateByUrl('/notifications');
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
    }
    else {
      this.validationMessages.validateAllFormFields(this.addNotificationForm);
    }
  }
}
