import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { FormBuilder } from '@angular/forms';
import { AppComponent } from '../../app.component';
import { BaseService } from 'src/app/helpers/common/base.service';
import { PreviousRouteService } from 'src/app/helpers/services/previous-root.service';
import { Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2'


interface otherFilter {
  value: string;
  viewValue: string;
}
export interface UserData {
  requestNo: string;
  progress: string;
  customerMobile: string;
  city: string;
  charity: string;
  name: string;
  driverName: string;
  pickupAt:string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
  'maroon', 'red'
];
const NAMES: string[] = [
  'Ahmed', 'Shani'
];
const MOBILENO: string[] = [
  '0501234123', '0501234156'
];
const EMIRATES: string[] = [
  'Dubai', 'Dubai'
];

/**
* @title Data table with sorting, pagination, and filtering.
*/

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {
  requests: any;
  response: any;
  pageSize: number = 0;
  pageNumber: number;
  totalItems: number;
  pageEvent: PageEvent;
  requestStatus: any = [];
  selectedFilter: any = "N";
  searchedValue: any;
  otherSelected: any = "OA";
  countryAllSelected: boolean = true;
  filters: any = "N";
  date: any;
  dateTo: any;
  dateFrom: any;
  country: any = "A";
  countryid: Number;
  countries: any;
  charities: any;
  donationTypes: any;
  charityId: Number;
  charityAllSelected: boolean = true;
  donationTypeId: Number;
  donationTypeAllSelected: boolean  = true;
  selectedPickUpFrom: any;
  selectedPickUpTo: any;
  stage: string;
  bookmarkRequestId: string;


  userCities: any = this.baseService.getLocalStorageItem('userObj').city;
  searchQuery = new Subject<string>();
  otherFilters: otherFilter[] = [
    { value: 'D', viewValue: 'Duplicate/Test' },
    { value: 'NA', viewValue: 'No Answer' },
    { value: 'OA', viewValue: 'All' }
  ];

  displayedColumns: string[] = ['requestNo','source', 'name', 'customerMobile', 'city', 'charity', 'createdAt',  'driverName'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  newChecked: boolean = true;
  allChecked: boolean = false;
  constructor(public globalMethodsService: GlobalMethodsService,
    public route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder, public appComp: AppComponent,
    private viewportScroller: ViewportScroller,
    public baseService: BaseService, public previousRouteService: PreviousRouteService) {
    if (this.baseService.getLocalStorageItem('userObj').country != null) {
      this.country = this.baseService.getLocalStorageItem('userObj').country;
    }
   

    if (this.route.url['value'].length > 1) {
      this.newChecked = false;
      this.allChecked = true;
      this.selectedFilter = "A";
      this.filters = "A"
      this.requestStatus.push('N', 'C', 'P');

    }
    else {
      this.newChecked = true;
      this.allChecked = false;
      this.requestStatus.push('N');
    }

    window.scroll(0, 0);
    

    
    this.countries = this.globalMethodsService.countries;

    if (this.country != "A") {
      this.countryid = this.countries.find(res => res.countryCode == this.country).id;
    }
    else { this.countryid = 0; }
    this.getCharities();
  this.getDonationTypes();

    this.response = this.route.snapshot.data.data;

    if (this.response.statusCode == 200) {
      //debugger
      this.requests = this.response.result.request;
      this.pageSize = this.response.result.paging.pageSize;
      this.totalItems = this.response.result.paging.totalItems;
      //this.pageNumber=this.response.result.paging.pageNumber;
    }
    else { this.requests = []; }
    this.dataSource = new MatTableDataSource(this.requests);
    this.searchQuery.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        this.applyFilter(value);
      });

      this.globalMethodsService.loader.stopAll();
  }

  RequestFilter(value: string) {
    this.selectedFilter = value;
    if (value != 'N') {
      this.newChecked = false;
     this.displayedColumns = ['requestNo', 'name', 'customerMobile', 'city', 'charity', 'createdAt', 'progress', 'pickupAt', 'driverName'];

    }
    else {
      this.displayedColumns = ['requestNo', 'name', 'customerMobile', 'city', 'charity', 'createdAt', 'driverName'];

      this.newChecked = true;
    }
    if (value == 'D' || value == 'NA' || value == 'OA') {
      this.otherSelected = value;
      this.selectedFilter = 'O';
    }
    if (value == "O") {
      this.otherSelected = "OA";
    }
    let data = {
      "pageNumber": 1,
      "pageSize": this.pageSize,
      "status": [],
      "cityId": [],
      "countryId": this.countryid,
      "customerId": Number(),
      "associationId": this.charityId,
      "donationTypeId": this.donationTypeId,
      "DriverId": Number(),
      "search": ''
    }
    this.pageNumber = 0;
    if (this.countryid == 0) {
      delete data.countryId;
    }
    if (this.charityId == 0) {
      delete data.associationId;
    }

    if (this.donationTypeId == 0) {
      delete data.donationTypeId;
    }
    this.globalMethodsService.loader.startBackground();
    if (this.searchedValue == "") {
      delete data.search;
    }
    else {
      data.search = this.searchedValue;
    }
    if (this.baseService.getLocalStorageItem('userObj').role == 'user') {


      this.baseService.getLocalStorageItem('userObj').city.forEach(c => {
        data.cityId.push(c.cityId);
      });
    }
    else {
      delete data.cityId;
    }
    if (value == 'A') {
     
      this.requestStatus = new Array();
      this.requestStatus.push('C', 'P', 'N');
      data.status.push('C', 'P', 'N')
    }
    else {
      this.requestStatus = new Array();
      this.requestStatus.push(value);
      data.status.push(value);
    }
    if (this.route.snapshot.url.length > 1) {
      if (this.route.url['value'][0].path == 'driver-requests') {
        data.DriverId = Number(this.route.snapshot.url[1].path);
        delete data.customerId;
      }
      else {
        data.customerId = Number(this.route.snapshot.url[1].path);
        delete data.DriverId;
      }
    }
    else {
      delete data.customerId;
      delete data.DriverId;
    }

    
    if(this.requestStatus == 'C'){
      this.displayedColumns = ['requestNo', 'name','weight', 'customerMobile', 'city', 'charity', 'createdAt', 'progress', 'pickupAt', 'driverName'];

    }

    this.httpHelper.post('Requests/paged', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        this.requests = this.response.result.request;
        this.pageSize = this.response.result.paging.pageSize;
        this.totalItems = this.response.result.paging.totalItems;
        //debugger
      }
      else {
        this.requests = [];
      }
      this.dataSource = new MatTableDataSource(this.requests);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if(this.selectedFilter != 'N'){
      this.displayedColumns = ['requestNo', 'name', 'customerMobile', 'city', 'charity', 'createdAt', 'progress', 'pickupAt', 'driverName'];

    }
    if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country' || this.baseService.getLocalStorageItem('userObj').role == 'country-requests') {

      this.countrySelectLoad();
      
    }

    this.route.queryParams.subscribe(params => {
      // if 'stage' query string is exist, then change the stage filter
      if(params.stage){

        // Convert request stage label to stage code
        switch(params.stage){
          case 'New Request': this.stage = 'N';
          break;

          case 'In Progress': this.stage = 'P';
          break;

          case 'Completed': this.stage = 'C';
          break;
          
        }

        this.RequestFilter(this.stage);
        if(params.rid) this.bookmarkRequestId = params.rid;
   
      }else{
        this.stage = 'N';
      }
    }
  );
  }
  ngAfterViewInit() {
    this.appComp.showHeader = true;
    //this.viewportScroller.scrollToAnchor('6102');
    
    
  }

  applyFilter(filterValue: string) {
    //debugger
    this.globalMethodsService.loader.startBackground();
    //this.dataSource.filter = filterValue.trim().toLowerCase();
    this.searchedValue = filterValue;
    let data = {
      "pageNumber": 1,
      "pageSize": this.pageSize,
      "status": [],
      "cityId": [],
      "associationId": Number(),
      "countryId": this.countryid,
      "customerId": Number(),
      "DriverId": Number(),
      "search": ''
    }
    if (this.countryid == 0) {
      delete data.countryId
    }
    if (this.charityId == 0) {
      delete data.associationId
    }
    if (this.searchedValue == "") {
      delete data.search;
    }
    else {
      data.search = this.searchedValue;
    }
    if (this.baseService.getLocalStorageItem('userObj').role == 'user') {


      this.baseService.getLocalStorageItem('userObj').city.forEach(s => {
        data.cityId.push(s.cityId);
      });
    }
    else {
      delete data.cityId;
    }
    if (this.selectedFilter == 'A') {
      //debugger
      this.requestStatus = new Array();
      this.requestStatus.push('C', 'P', 'N');
      data.status.push('C', 'P', 'N')
    }
    else {
      this.requestStatus = new Array();
      this.requestStatus.push(this.selectedFilter);
      data.status.push(this.selectedFilter);
    }
    if (this.route.snapshot.url.length > 1) {
      if (this.route.url['value'][0].path == 'driver-requests') {
        data.DriverId = Number(this.route.snapshot.url[1].path);
        delete data.customerId;
      }
      else {
        data.customerId = Number(this.route.snapshot.url[1].path);
        delete data.DriverId;
      }
    }
    else {
      delete data.customerId;
      delete data.DriverId;
    }

    this.httpHelper.post('Requests/paged', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        this.requests = this.response.result.request;
        this.pageSize = this.response.result.paging.pageSize;
        this.totalItems = this.response.result.paging.totalItems;
        //debugger
      }
      else {
        this.requests = [];
      }
      this.dataSource = new MatTableDataSource(this.requests);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  handlePage(ev) {
    this.globalMethodsService.loader.startBackground();
    //debugger
    let data = {
      "pageNumber": ev.pageIndex + 1,
      "pageSize": ev.pageSize,
      "status": this.requestStatus,
      "cityId": [],
      "countryId": this.countryid,
      "associationId": this.charityId,
      "customerId": Number(),
      "DriverId": Number(),
      "search": ''
    }
    if (this.countryid == 0) {
      delete data.countryId;
    }
    if (this.charityId == 0) { delete data.associationId }
    if (this.searchedValue == "") {
      delete data.search;
    }

    else {
      data.search = this.searchedValue;
    }
    if (this.baseService.getLocalStorageItem('userObj').role == 'user') {

      this.baseService.getLocalStorageItem('userObj').city.forEach(s => {
        data.cityId.push(s.cityId);
      });
    }
    else {
      delete data.cityId;
    }
    if (this.route.snapshot.url.length > 1) {
      if (this.route.url['value'][0].path == 'driver-requests') {
        data.DriverId = Number(this.route.snapshot.url[1].path);
        delete data.customerId;
      }
      else {
        data.customerId = Number(this.route.snapshot.url[1].path);
        delete data.DriverId;
      }
    }
    else {
      delete data.customerId;
      delete data.DriverId;
    }
    this.httpHelper.post('Requests/paged', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        this.requests = this.response.result.request;
        this.pageNumber = this.response.result.paging.pageNumber - 1;
        //debugger
      }
      else {
        this.requests = [];
      }
      this.pageSize = ev.pageSize;
      this.totalItems = ev.length;

      this.dataSource = new MatTableDataSource(this.requests);
      this.dataSource.sort = this.sort;
    });
    return ev;
  }
  pickupDateSearchFrom(pickupDateFrom: any) {
    this.globalMethodsService.loader.startBackground();
    let selectedDateFrom = this.globalMethodsService.setDate(pickupDateFrom.value)
    this.selectedPickUpFrom = selectedDateFrom;
    let data = null;
    data = {
      "pageNumber": 1,
      "pageSize": this.pageSize,
      "status": [],
      "cityId": [],
      "customerId": Number(),
      "countryId": this.countryid,
      "associationId": this.charityId,
      "DriverId": Number(),
      "search": '',
      "pickupAtFrom": JSON.stringify(selectedDateFrom).substr(1, 10)
    }

    if(this.selectedPickUpTo != null || this.selectedPickUpTo == ""){
      let selectedDateTo = this.globalMethodsService.setDateNormal(this.selectedPickUpTo);
      this.selectedPickUpTo = selectedDateTo;
       data = {
        "pageNumber": 1,
        "pageSize": this.pageSize,
        "status": [],
        "cityId": [],
        "customerId": Number(),
        "countryId": this.countryid,
        "associationId": this.charityId,
        "DriverId": Number(),
        "search": '',
        "pickupAtFrom": JSON.stringify(selectedDateFrom).substr(1, 10),
        "pickupAtTo": JSON.stringify(selectedDateTo).substr(1, 10)
        
      }
    }
    if (this.charityId == 0) {
      delete data.associationId;
    }
    if (this.countryid == 0) {
      delete data.countryId;
    }
    if (this.searchedValue == "") {
      delete data.search;
    }
    else {
      data.search = this.searchedValue;
    }
    if (this.baseService.getLocalStorageItem('userObj').role == 'user') {


      this.baseService.getLocalStorageItem('userObj').city.forEach(s => {
        data.cityId.push(s.cityId);
      });
    }
    else {
      delete data.cityId;
    }
    if (this.selectedFilter == 'A') {
      //debugger
      this.requestStatus = new Array();
      this.requestStatus.push('C', 'P', 'N');
      data.status.push('C', 'P', 'N')
    }
    else {
      this.requestStatus = new Array();
      this.requestStatus.push(this.selectedFilter);
      data.status.push(this.selectedFilter);
    }
    if (this.route.snapshot.url.length > 1) {
      if (this.route.url['value'][0].path == 'driver-requests') {
        data.DriverId = Number(this.route.snapshot.url[1].path);
        delete data.customerId;
      }
      else {
        data.customerId = Number(this.route.snapshot.url[1].path);
        delete data.DriverId;
      }
    }
    else {
      delete data.customerId;
      delete data.DriverId;
    }

    this.httpHelper.post('Requests/paged', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        this.requests = this.response.result.request;
        this.pageSize = this.response.result.paging.pageSize;
        this.totalItems = this.response.result.paging.totalItems;
        //debugger
      }
      else {
        this.requests = [];
      }
      this.dataSource = new MatTableDataSource(this.requests);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  pickupDateSearchTo(pickupDateTo: any) {
    this.globalMethodsService.loader.startBackground();
    let selectedDateTo = this.globalMethodsService.setDate(pickupDateTo.value)
    this.selectedPickUpTo = selectedDateTo;
    let data = null;
    data = {
      "pageNumber": 1,
      "pageSize": this.pageSize,
      "status": [],
      "cityId": [],
      "customerId": Number(),
      "countryId": this.countryid,
      "associationId": this.charityId,
      "DriverId": Number(),
      "search": '',
      "pickupAtTo": JSON.stringify(selectedDateTo).substr(1, 10)
    }

    if(this.selectedPickUpFrom != null || this.selectedPickUpFrom == ""){
      let selectedDateFrom = this.globalMethodsService.setDateNormal(this.selectedPickUpFrom);
      this.selectedPickUpFrom = selectedDateFrom;
       data = {
        "pageNumber": 1,
        "pageSize": this.pageSize,
        "status": [],
        "cityId": [],
        "customerId": Number(),
        "countryId": this.countryid,
        "associationId": this.charityId,
        "DriverId": Number(),
        "search": '',
        "pickupAtFrom": JSON.stringify(selectedDateFrom).substr(1, 10),
        "pickupAtTo": JSON.stringify(selectedDateTo).substr(1, 10)
        
      }
    }
    if (this.charityId == 0) {
      delete data.associationId;
    }
    if (this.countryid == 0) {
      delete data.countryId;
    }
    if (this.searchedValue == "") {
      delete data.search;
    }
    else {
      data.search = this.searchedValue;
    }
    if (this.baseService.getLocalStorageItem('userObj').role == 'user') {


      this.baseService.getLocalStorageItem('userObj').city.forEach(s => {
        data.cityId.push(s.cityId);
      });
    }
    else {
      delete data.cityId;
    }
    if (this.selectedFilter == 'A') {
      //debugger
      this.requestStatus = new Array();
      this.requestStatus.push('C', 'P', 'N');
      data.status.push('C', 'P', 'N')
    }
    else {
      this.requestStatus = new Array();
      this.requestStatus.push(this.selectedFilter);
      data.status.push(this.selectedFilter);
    }
    if (this.route.snapshot.url.length > 1) {
      if (this.route.url['value'][0].path == 'driver-requests') {
        data.DriverId = Number(this.route.snapshot.url[1].path);
        delete data.customerId;
      }
      else {
        data.customerId = Number(this.route.snapshot.url[1].path);
        delete data.DriverId;
      }
    }
    else {
      delete data.customerId;
      delete data.DriverId;
    }

    this.httpHelper.post('Requests/paged', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        this.requests = this.response.result.request;
        this.pageSize = this.response.result.paging.pageSize;
        this.totalItems = this.response.result.paging.totalItems;
        //debugger
      }
      else {
        this.requests = [];
      }
      this.dataSource = new MatTableDataSource(this.requests);
      // this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  clearFilters() {
    this.searchedValue = '';
    this.newChecked = true;
    this.allChecked = false;
    this.countryid = 0;
    this.charityId = 0;
    this.charityAllSelected = true;
    this.countryAllSelected = true;
    if (this.baseService.getLocalStorageItem('userObj').country != null) {
      this.country = this.baseService.getLocalStorageItem('userObj').country;
      this.countryid = this.countries.find(res => res.countryCode == this.country).id;
    }
    else { this.country = "A"; }
    this.RequestFilter('N');
    this.getCharities();
    this.otherSelected = 'OA';
    this.filters = 'N';
  }
  countrySelect(ev) {
    this.countryid = +ev.currentTarget.value;
    if (this.countryid != 0) {
      this.country = this.countries.find(res => res.id == this.countryid).countryCode;
      this.countryAllSelected = false;
    }
    else { this.country = "A"; this.countryAllSelected = true; }
    this.RequestFilter(this.selectedFilter);
    this.getCharities();
  }

  countrySelectLoad() {
    this.countryid = 1;
    if (this.countryid != 0) {
      this.country = 'QTR';
      this.countryAllSelected = false;
      //  this.filterByDate();
    }
    this.RequestFilter(this.selectedFilter);
    this.getCharities();
  }
  charityChange(ev) {
    this.charityId = +ev.currentTarget.value;
    this.RequestFilter(this.selectedFilter);
    if (this.charityId == 0) {
      this.charityAllSelected = true;
    }
    else { this.charityAllSelected = false; }
  }

  donationTypeChange(ev) {
    this.donationTypeId = +ev.currentTarget.value;
    this.RequestFilter(this.selectedFilter);
    if (this.donationTypeId == 0) {
      this.donationTypeAllSelected = true;
    }
    else { this.donationTypeAllSelected = false; }
  }

  getCharities() {
    let data = {
      countryCode: this.country
    };
    //debugger
    this.httpHelper.put('Common/getCharities', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
        //debugger
        //console.log(this.charities);
        this.charityId = 0;
      }
      else { this.charities = []; }
      // this.dwsTransactions = this.byDriver;
    });
  }

  getDonationTypes() {
    let data = {
      countryCode: this.country
    };
    //debugger
    this.httpHelper.getWithAuthToken('Common/getDonationTypes').then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.donationTypes = this.response.result;
        //debugger
        //console.log(this.charities);
        this.charityId = 0;
      }
      else { this.donationTypes = []; }
      // this.dwsTransactions = this.byDriver;
    });
  }
  showSweetAlert(row,action) {
    let text='';
    if(action=='duplicate')
    {
      text='This request will be marked as duplicate/test'
    }
    else if(action == 'noAnswer'){
      text="This request will be marked as no answer"
    }else if(action='delete'){
      text='This request will be deleted!'
    }
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value && (action == 'duplicate' || action == 'noAnswer')) {
        this.markAsDuplicate(row,action);
      }else if(result.value && action == 'delete'){
        this.markAsDeleted(row,action);
      }
    })
  }
  markAsDuplicate(row,action){
    let data={
      Id:row.id,
      modifiedBy:this.baseService.getLocalStorageItem('userObj').id,
      ModifiedAt:this.convertDateTime(new Date(), new Date()),
      IsDuplicate:true,
      NoAnswer:true,
      Status:''
    }
    if(row.status=='New Request'){data.Status='N'}
    else if(row.status=='In Progress'){data.Status='P'}

    //debugger
    if(action=='duplicate'){
      delete data.NoAnswer
    }
    else{
      delete data.IsDuplicate
    }
    this.httpHelper.put('Requests/markAsDuplicate', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        //debugger
        this.RequestFilter(this.selectedFilter)
      }
      else {
        //debugger
        this.globalMethodsService.showErrorToast();
      }
    });
  }

  markAsDeleted(row,action){
    let data={
      Id:row.id,
      modifiedBy:this.baseService.getLocalStorageItem('userObj').id,
      ModifiedAt:this.convertDateTime(new Date(), new Date()),
      IsDeleted:true,
      IsActive:false,
      IsDuplicate:false,
      NoAnswer:false,
      Status:''
    }
    if(row.status=='New Request'){data.Status="N"}
    else if(row.status=='In Progress'){data.Status="P"}

    //debugger

    this.httpHelper.put('Requests/markAsDeleted', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        //debugger
        this.RequestFilter(this.selectedFilter)
      }
      else {
        //debugger
        this.globalMethodsService.showErrorToast();
      }
    });
  }
  convertDateTime(day, getTime) {
    let date;
    if (day._d) {
      date = this.convertDate(day._d);
    }
    else {
      date = this.convertDate(day);
    }
    let time = this.convertTime(getTime);
    // this.updateRequestForm.value.date
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM.toLowerCase() == "pm" && hours < 12) hours = hours + 12;
    if (AMPM.toLowerCase() == "am" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    time = sHours + ":" + sMinutes + ":00";
    var newDate = date + "T" + time;
    return newDate;
  }
  convertDate(date) {
    var dd = date.getDate();
    if (dd < 10) {
      dd = ('0' + dd).slice(-2);
    }
    var mm = date.getMonth() + 1;
    if (mm < 10) {
      mm = ('0' + mm)
    }
    var yyyy = date.getFullYear();
    date = yyyy + '-' + mm + '-' + dd;
    return (date);
  }
  convertTime(getTime) {
    return getTime.value = new Date(getTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, ['email', 'firstname', 'lastname']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'Index,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }
}
