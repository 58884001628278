import { Component, OnInit, Input } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';

@Component({
  selector: 'app-feed-back-model',
  templateUrl: './feed-back-model.component.html',
  styleUrls: ['./feed-back-model.component.scss']
})
export class FeedBackModelComponent implements OnInit {

 @Input() feedBackDetail: any;

  constructor(public globalMethodsService:GlobalMethodsService) { }

  ngOnInit() {
    //console.log(this.feedBackDetail);
  }

}
