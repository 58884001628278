import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';
  public enableDebug = true;
  public userIdleConfig={timeout:0,idle:0
  }
  constructor() {
  }
}
