import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { FormBuilder } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { PreviousRouteService } from 'src/app/helpers/services/previous-root.service';
import { AppComponent } from 'src/app/app.component';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feed-back',
  templateUrl: './feed-back.component.html',
  styleUrls: ['./feed-back.component.scss']
})
export class FeedBackComponent implements OnInit {
  response: any;
  feedBacks: any;
  allChecked: boolean = true;
  displayedColumns: string[] = ['requestNo', 'name', 'mobileNo', 'country', 'city', 'feedBackDate', 'rating', 'remarks'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  closeResult: string;
  feedBackDetail: any
  countryid: number = 0;
  cityId: number = 0;
  cities: any;
  cityAllSelected: boolean = true;
  selectedStatus: any = "ALL";
  countryAllSelected: boolean = true;
  constructor(public globalMethodsService: GlobalMethodsService,
    public route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder, public appComp: AppComponent,
    public baseService: BaseService, public previousRouteService: PreviousRouteService, private modalService: NgbModal) {
    window.scroll(0, 0);
    this.cities = this.globalMethodsService.cities;
    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      this.feedBacks = this.response.result;
      //debugger
      this.dataSource = new MatTableDataSource(this.feedBacks);
    }
    else {
      this.feedBacks = [];
    }
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  FeedBackFilter(value: string) {
    this.globalMethodsService.loader.startBackground();
    this.selectedStatus = value;
    let userObj = this.baseService.getLocalStorageItem('userObj');
    let api = 'FeedBack/' + value + '?countryId=' + this.countryid + '&cityId=' + this.cityId;
    if (userObj.role == 'charityUser') {
      api = 'FeedBack/' + value + '?charityId=' + userObj.charityId;
    }
    this.httpHelper.getWithAuthToken(api).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      //debugger
      if (this.response.statusCode == 200) {
        this.feedBacks = this.response.result;
        //debugger
      }
      else {
        this.feedBacks = [];
      }
      this.dataSource = new MatTableDataSource(this.feedBacks);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  openVerticallyCentered(row, content) {
    this.feedBackDetail = row;
    this.modalService.open(content, { centered: true, windowClass: 'theme-modal' });
  }
  countrySelect(ev) {
    //debugger
    this.countryid = +ev.currentTarget.value;
    this.globalMethodsService.loader.startBackground();
    this.getCities(this.countryid);
    if (this.countryid != 0) {
      this.countryAllSelected = false;
    }
    else { this.countryAllSelected = true; }
    this.FeedBackFilter(this.selectedStatus);
  }
  getCities(countryId: number) {
    this.httpHelper.getWithAuthToken('Common/getCities?countryId=' + countryId).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.cities = this.response.result;
        //console.log(this.cities);
        return this.cities;
      }
      else {
        this.cities = [];
        return this.cities;
      }
      // this.dwsTransactions = this.byDriver;

    })
  }
  citySelect(ev) {
    this.cityId = +ev.currentTarget.value;
    if (this.cityId != 0) {
      this.cityAllSelected = false;
    }
    else {
      this.cityAllSelected = true;
    }
    this.FeedBackFilter(this.selectedStatus);
  }
}
