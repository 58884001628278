import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { BaseService } from 'src/app/helpers/common/base.service';

export interface UserData {
  requestsName: string;
  mobileNo: string;
  country: string;
  city: string;
  numberOfRequest: string;
}

/**
* @title Data table with sorting, pagination, and filtering.
*/

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  response: any;
  customers: any;
  statesCode: string[];
  countryAllSelected:boolean=true;
  toppings = new FormControl();
  toppingList: any[] = [
    { name: 'Dubai', value: 'DXB' },
    { name: 'Abu Dhabi', value: 'AUH' },
    { name: 'Alain', value: 'ALA' },
    { name: 'Ajman', value: 'QAJ' },
    { name: 'Sharjah', value: 'SHJ' },
    { name: 'Ras al Khaimah', value: 'RKT' },
    { name: 'Umm al-Qaiwain', value: 'QIW' },
    { name: 'Fujairah', value: 'FJR' }];
 
  countryid:number=0;
  cityAllSelected:boolean=true;
  cityId:number=0;
  displayedColumns: string[] = ['requestsName', 'mobileNo', 'country', 'city', 'numberOfRequest'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public globalMethodsService: GlobalMethodsService,
    private route: ActivatedRoute,
    public baseService: BaseService,
    public httpHelper: HttpClientService) {
    window.scroll(0, 0);
    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      this.customers = this.response.result;
      //debugger
      this.dataSource = new MatTableDataSource(this.customers);

    }
    else {
      this.customers = [];
    }

    if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country') {
      console.log("ddfsdfsf")
      this.countrySelectLoad();
      
    }
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country') {
      console.log("ddfsdfsf")
      this.countrySelectLoad();
      
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  countrySelect(ev){
    this.countryid = +ev.currentTarget.value;
    this.globalMethodsService.getCities(this.countryid);
    if (this.countryid != 0) {
      this.countryAllSelected = false;
    }
    else { this.countryAllSelected = true; }
    this.filterCustomers();
  }

  countrySelectLoad(){
    this.countryid = 1;
    this.globalMethodsService.getCities(this.countryid);
    if (this.countryid != 0) {
      this.countryAllSelected = false;
    }
    else { this.countryAllSelected = true; }
    this.filterCustomers();
  }
  citySelect(ev){
    this.cityId=+ev.currentTarget.value;
    if(this.cityId!=0){
      this.cityAllSelected=false;
    }
    else{
      this.cityAllSelected=true;
    }
    this.filterCustomers();
  }
  filterCustomers(){
    this.globalMethodsService.loader.startBackground();
    this.httpHelper.getWithAuthToken('Customer?countryId='+this.countryid+'&cityId='+this.cityId).then(result => {
      this.response = result;
      
      if (this.response.result.length > 0) {
        this.customers = this.response.result;
        //console.log(this.customers);
        this.dataSource = new MatTableDataSource(this.customers);
        this.globalMethodsService.loader.stopAll();
        return this.customers;
      }
      else { this.customers = [];
        this.dataSource=new MatTableDataSource(this.customers) ;
        this.globalMethodsService.loader.stopAll();
        return this.customers;}
        
     // this.dwsTransactions = this.byDriver;
    
    })
   
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  filterCustomersLoad(){
    this.globalMethodsService.loader.startBackground();
    this.httpHelper.getWithAuthToken('Customer?countryId='+this.countryid).then(result => {
      this.response = result;
      
      if (this.response.result.length > 0) {
        this.customers = this.response.result;
        //console.log(this.customers);
        this.dataSource = new MatTableDataSource(this.customers);
        this.globalMethodsService.loader.stopAll();
        return this.customers;
      }
      else { this.customers = [];
        this.dataSource=new MatTableDataSource(this.customers) ;
        this.globalMethodsService.loader.stopAll();
        return this.customers;}
        
     // this.dwsTransactions = this.byDriver;
    
    })
   
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

