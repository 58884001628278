import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/helpers/common/base.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClientService } from '../http/http-client.service'
@Injectable({
  providedIn: 'root'
})
export class GlobalMethodsService {
  key: string = "melabes";
  fileList = [];
  dbFileList = [];
  fileError: any;
  responseUpload: any;
  departmentRs: any;
  attachmentType: any;
  cities: any;
  userObj;
  response: any;
  countries: any;
  constructor(
    public toastrService: ToastrService,
    public baseService: BaseService,
    public router: Router,
    public loader: NgxUiLoaderService,
    public httpHelper: HttpClientService) {
    this.getCountries();
    this.setUserObj();
  }
  showErrorToast() {
    this.toastrService.error("Something went wrong. Please try again");
  }
  showAlreadyExisit() {
    this.toastrService.error("User name already exist");
  }
  setUserObj() {
    if (this.baseService.getLocalStorageItem('userObj')) {
      this.userObj = this.baseService.getLocalStorageItem('userObj');
    }
    else {
      this.userObj = '';
    }
  }

  logout() {
    var lang = localStorage.getItem('lang');
    localStorage.clear();
    this.setUserObj();
    this.router.navigateByUrl('/');
    localStorage.setItem('lang',lang);
  }
  setFromDate(date, months) {
    date.setMonth(date.getMonth() + months);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    var convertedDate = date.toISOString().slice(0, 10);
    return convertedDate;
  }
  setDate(getDate) {
    var date = new Date(getDate);
    date.setDate(date.getDate() + 1);
    date = new Date(date);
    var convertedDate = date.toISOString().slice(0, 10);
    return convertedDate;
  }

  setDateNormal(getDate) {
    var date = new Date(getDate);
    date.setDate(date.getDate());
    date = new Date(date);
    var convertedDate = date.toISOString().slice(0, 10);
    return convertedDate;
  }

  convertUTCDateToLocalDate(date) {
    var oldDate = new Date(date);
    var newDate = new Date(date);
    newDate.setMinutes(oldDate.getMinutes() - oldDate.getTimezoneOffset());
    return newDate;
  }
  getcountryCode(countryId) {
    let countryCode = this.countries.find(element => element.id == countryId).countryCode;
    return countryCode
  }
  getCountries() {
    this.httpHelper.getWithAuthToken('Common/getCountries').then(result => {
      this.response = result;
      this.loader.stopAll();
      if (this.response.result.length > 0) {
        this.countries = this.response.result;
        return this.countries;
      }
      else {
        this.countries = [];
        return this.countries;
      }
    })
  }
  getCities(countryId: number) {
    this.httpHelper.getWithAuthToken('Common/getCities?countryId=' + countryId).then(result => {
      this.response = result;
      this.loader.stopAll();
      if (this.response.result.length > 0) {
        this.cities = this.response.result;
        return this.cities;
      }
      else {
        this.cities = [];
        return this.cities;
      }
    })
  }
}
