import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { AppComponent } from 'src/app/app.component';
import { UserIdleService } from 'angular-user-idle';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Console } from 'console';
import { isFunction } from 'util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  aonkMode: boolean = false;
  alkhaireyaMode: boolean = false;
  errorResponse: string;
  year: any;
  switchLang: string;
  response: any;
  currentUrl: string;
  constructor(@Inject(DOCUMENT) private document: Document,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public baseService: BaseService,
    public httpHelper: HttpClientService,
    public route: ActivatedRoute,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public appComp: AppComponent,
    private userIdle: UserIdleService) {
    
      
    if(window.location.hostname == "alkhaireya.net"){
      this.alkhaireyaMode = true;
    }else if(window.location.hostname == "aonk.net"){
      this.aonkMode = true;
    }else if(window.location.hostname == "localhost"){
      
    }

    this.appComp.showHeader = false;
    if (this.baseService.getLocalStorageItem('userObj')) {
      this.appComp.showHeader = false;
      this.authGuardService.getRoleBaseRoute(this.baseService.getLocalStorageItem('userObj').role);
    }
    this.currentUrl = this.router.url;


  }

  ngOnInit() {


    this.appComp.showHeader = false;
    this.year = new Date().getFullYear();
    this.loginForm = this.formBuilder.group({
      'userName': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.compose([Validators.required])],
    });


  }

  switchLanguage(){
    let currentLang = this.baseService.getLocalStorageItem('lang');
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;

    if(currentLang == 'en'){
      this.baseService.createLocalStorageItem('lang','ar');
      this.translate.setDefaultLang('ar');
      htmlTag.dir = 'rtl';
    }else if(currentLang == 'ar'){

      this.baseService.createLocalStorageItem('lang','en');
      this.translate.setDefaultLang('en');
      htmlTag.dir = 'ltr';

    }else{
      this.baseService.createLocalStorageItem('lang','en');
      this.translate.setDefaultLang('en');
      htmlTag.dir = 'ltr';
    }

  }
  onLoginFormSubmit() {
   
    if (this.loginForm.valid) {
      this.globalMethodsService.loader.startBackground();
      this.errorResponse = null;
      this.httpHelper.post('User/login', this.loginForm.value).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.appComp.showHeader = false;
        // //debugger
        if (this.response.statusCode == 200) {
          this.userIdle.startWatching();
          // Start watching when user idle is starting.
          this.userIdle.onTimerStart().subscribe();
          this.userIdle.onTimeout().subscribe(() => this.globalMethodsService.logout());
          //Start watching for user inactivity.
          localStorage.setItem('Token', this.response.result[0]);
          this.baseService.createLocalStorageItem('userObj', this.response.result[1]);
          this.globalMethodsService.setUserObj();
          this.authGuardService.getRoleBaseRoute(this.response.result[1].role);
          let rUrl = this.baseService.getLocalStorageItem('rUrl');
          if(rUrl != "" && rUrl != "null" && rUrl != undefined) {
            console.log(rUrl)
            this.router.navigateByUrl(rUrl);
          }else{
            if(this.response.result[1].role == 'charityUser'){
              this.router.navigateByUrl('/trucks-log');
            }
            
            
          }
        }
        else if (this.response.statusCode == 401) {
          this.errorResponse = 'Your Account is deactivated By Administrator';
        }
        else {

          this.errorResponse = 'لقد ادخلت اسم مستخدم أو كلمة مرور خاطئة';
        }
      });
    }
    else {
      this.validationMessages.validateAllFormFields(this.loginForm);
    }
  }
}
