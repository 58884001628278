import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BaseService } from "../common/base.service";
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Injectable } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable()
export class HnResolver implements Resolve<any> {
  countries: any;
  response: any;
  constructor(public baseService: BaseService,
    public httpHelper: HttpClientService,
    public globalService: GlobalMethodsService,
    public router: Router, ) {
  }
  resolve(route: ActivatedRouteSnapshot) {
    if (route.url[0].path == 'users') { return this.getUsers(); }
    else if (route.url[0].path == 'drivers') { return this.getDrivers(); }
    else if (route.url[0].path == 'add-user' || route.url[0].path == 'add-driver' || route.url[0].path == 'edit-user' || route.url[0].path == 'edit-driver' || route.url[0].path == 'edit-charity') { return this.getStates(); }
    else if (route.url[0].path == 'customers') { return this.getCustomers(); }
    else if (route.url[0].path == 'weights') { return this.getWeights(); }
    else if (route.url[0].path == 'trucks-log') { return this.getTruckLogs(); }
    else if (route.url[0].path == 'requests') {
      let cId;
      if (route.url.length > 1) { cId = route.url[1].path; }
      else { cId = ''; }
      return this.getRequests(cId);
    }
    else if (route.url[0].path == 'driver-requests') {
      let dId;
      if (route.url.length > 1) { dId = route.url[1].path; }
      else { dId = ''; }
      return this.getDriverRequests(dId);
    }
    else if (route.url[0].path == 'request-details') {
      let reqId = route.url[1].path;
      return this.getRequestDetails(reqId);
    }
    else if (route.url[0].path == 'dashboard') { return this.getByState(); }
    else if (route.url[0].path == 'notifications') { return this.getNotifications(); }
    else if (route.url[0].path == 'drivers-list') { return this.getByDriver() }
    else if (route.url[0].path == 'customers-feedback') { return this.getFeedBack(); }
    else if (route.url[0].path == 'cities') { return this.getCities(); }
    else if (route.url[0].path == 'countries') { return this.getCountries(); 
    }    
    else if (route.url[0].path == 'edit-country') {
      let countryId = route.url[1].path;
      return this.getCountryById(countryId);
    }
    else if (route.url[0].path == 'edit-city') {
      let cityId = route.url[1].path;
      return this.getCityById(cityId);
    }
    else if (route.url[0].path == 'charities') { return this.getCharities(); }
    else if (route.url[0].path == 'donation-types') { return this.getDonationTypes(); 
    }  
    else if (route.url[0].path == 'edit-donation-type') { 
      let donationTypeId = route.url[1].path;
      return this.getDonationType(donationTypeId);
    } else if (route.url[0].path == 'donation-boxes-list') { 
      return this.getDonationBoxes();
    } else if (route.url[0].path == 'edit-donation-box') { 
      let donationBoxId = route.url[1].path;
      return this.getDonationBox(donationBoxId);
    }else if (route.url[0].path == 'donation-box-details') { 
      let donationBoxId = route.url[1].path;
      return this.getDonationBoxDetails(donationBoxId);
    }else if (route.url[0].path == 'fill-donation-box-check-report') { 
      let donationBoxId = route.url[1].path;
      return this.getDonationBoxDetails(donationBoxId);
    }else if (route.url[0].path == 'fill-donation-box-maintenance-report') { 
      let donationBoxId = route.url[1].path;
      return this.getDonationBoxDetails(donationBoxId);
    }else if (route.url[0].path == 'donation-box-weight-list') { 
      return this.getDonationBoxWeightRecords();
    }else if (route.url[0].path == 'donation-box-charity-contracts-list') { 
      return this.getDonationBoxCharityContract();
    }else if (route.url[0].path == 'donation-box-financial-settlements-list') { 
      return this.getDonationBoxFinanicalSettlements();
    }
  }

  getDonationBoxFinanicalSettlements(){
    this.globalService.loader.start();
    if (this.baseService.getLocalStorageItem('userObj').role == 'charityUser') {
      let charityId = this.baseService.getLocalStorageItem('userObj').charityId;
      return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxFinancialSettlementsByCharityId?charityId=' +charityId );
    }
    return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxFinancialSettlements');
  }

  getDonationBoxCharityContract(){
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxCharityContracts');
  }

  getDonationBoxWeightRecords(){
    this.globalService.loader.start();
    if (this.baseService.getLocalStorageItem('userObj').role == 'charityUser') {
      let charityId = this.baseService.getLocalStorageItem('userObj').charityId;
      return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecordsByCharityId?charityId=' + charityId);
    }
    return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxWeightRecords');
  }

  getUsers() {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('User')
  }
  getDrivers() {
    this.globalService.loader.start();
    if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country') {
      return this.httpHelper.getWithAuthToken('Drivers?countryId=1');
    }

    return this.httpHelper.getWithAuthToken('Drivers');
  }
  getStates() {
    this.globalService.loader.start();
    return this.httpHelper.get('Common/getCities?countryId=1')
  }
  getCustomers() {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Customer')
  }

  getDonationBoxes() {
    this.globalService.loader.start();
    if (this.baseService.getLocalStorageItem('userObj').role == 'charityUser') {
      let charityId = this.baseService.getLocalStorageItem('userObj').charityId;
      return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxesByCharityId?charityId=' + charityId);
    }

    return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxes');
  }
  

  getDonationBox(donationBoxId) {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxById?id='+donationBoxId);
  }

  getDonationBoxDetails(donationBoxId) {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxById?id='+donationBoxId);
  }
  
  getWeights() {
    this.globalService.loader.start();
    let startDate = new Date();
    var toDate = this.baseService.setToDate(new Date());
    var fromDate = this.globalService.setFromDate(startDate, -3);
    var dates = {
      startDate: fromDate,
      endDate: toDate,
      userId: this.baseService.getLocalStorageItem('userObj').id
    }
    return this.httpHelper.put('Drivers/getLog', dates);
  }

  getTruckLogs() {
    this.globalService.loader.start();
    let startDate = new Date();
    var toDate = this.baseService.setToDate(new Date());
    var fromDate = this.globalService.setFromDate(startDate, -3);
    var dates = {
      startDate: fromDate,
      endDate: toDate,
      userId: this.baseService.getLocalStorageItem('userObj').id
    }
    return this.httpHelper.getWithAuthToken('TruckWeight/getTrucksWeights');
  }

  getRequests(customerId) {
    this.globalService.loader.start();
    let data;
    if (this.baseService.getLocalStorageItem('userObj').role == 'user') {
      data = {
        "pageNumber": 1,
        "pageSize": 25,
        "cityId": [],
        "status": ["N"]
      }
      this.baseService.getLocalStorageItem('userObj').city.forEach(s => {
        data.cityId.push(s.cityId);
      });
    }
    else {
      data = {
        "pageNumber": 1,
        "pageSize": 25,
        "status": ["N"]
      }
    }
    if (customerId != '') {
      data = {
        "pageNumber": 1,
        "pageSize": 25,
        "customerId": Number(customerId),
        "status": []
      }
      data.status.push('C', 'P', 'N')
    }
    return this.httpHelper.postWithAuthToken('Requests/paged', data)
  }
  getRequestDetails(id) {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Requests/' + id)
  }
  getByState() {
    this.globalService.loader.start();
    let startDate = new Date();
    startDate = this.globalService.setFromDate(startDate, -3);
    let endDate = this.globalService.setDate(new Date());
    let data = {
      "startDate": startDate,
      "endDate": endDate,
      "countryId": 0,
      "charityId": 0
    }
    if (this.baseService.getLocalStorageItem('userObj').role == 'charityUser' && this.baseService.getLocalStorageItem('userObj').charityId > 0) {
      data.charityId = this.baseService.getLocalStorageItem('userObj').charityId;
      delete data.countryId;
    }
    else {
      delete data.charityId;
    }
    return this.httpHelper.postWithAuthToken('Common/byState', data)
  }
  getNotifications() {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Notification')
  }
  getByDriver() {
    this.globalService.loader.start();
    let startDate = new Date();
    startDate = this.globalService.setFromDate(startDate, -3);
    let endDate = this.globalService.setDate(new Date());
    let data = {
      "startDate": startDate,
      "endDate": endDate,
    }
    //debugger
    return this.httpHelper.postWithAuthToken('Common/byDriver', data)
  }
  getDriverRequests(DriverId) {
    this.globalService.loader.start();
    let data;
    if (this.baseService.getLocalStorageItem('userObj').role == 'user') {
      data = {
        "pageNumber": 1,
        "pageSize": 25,
        "state": [],
        "status": ["N"]
      }
      this.baseService.getLocalStorageItem('userObj').state.forEach(s => {
        data.state.push(s.code);
      });
    }
    else {
      data = {
        "pageNumber": 1,
        "pageSize": 25,
        "status": ["N"]
      }
    }
    if (DriverId != '') {
      data = {
        "pageNumber": 1,
        "pageSize": 25,
        "DriverId": Number(DriverId),
        "status": []
      }
      data.status.push('C', 'P', 'N')
    }
    return this.httpHelper.postWithAuthToken('Requests/paged', data)
  }
  getFeedBack() {
    this.globalService.loader.start();
    if (this.baseService.getLocalStorageItem('userObj').role == 'charityUser') {
      let charityId = this.baseService.getLocalStorageItem('userObj').charityId;
      return this.httpHelper.getWithAuthToken('FeedBack/ALL?charityId=' + charityId)
    } if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country'){
      return this.httpHelper.getWithAuthToken('FeedBack/ALL?charityId=86')
    } 
    else {
      return this.httpHelper.getWithAuthToken('FeedBack')
    }
  }
  getCharities() {
    this.globalService.loader.start();
    let data = {
      countryCode: 'BRN',
      active: 'A'
    }
    return this.httpHelper.put('Common/getCharities', data)
  }
  getCities() {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Common/getCities?countryId=3&active=A')
  }

  getCountries() {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Common/getCountries?countryId=3&active=A')
  }
  getCityById(cityId) {
    cityId = +cityId;
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Common/getCity?cityId=' + cityId)
  }

  getCountryById(countryId) {
    countryId = +countryId;
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Common/getCountry?countryId=' + countryId)
  }

  getDonationTypes() {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Common/getDonationTypes')
  }

  getDonationType(donationTypeId) {
    this.globalService.loader.start();
    return this.httpHelper.getWithAuthToken('Common/getDonationType?donationTypeId=' + donationTypeId);
  }
}