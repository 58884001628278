import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuardService } from './helpers/auth/auth-guard.service';
import { LoginComponent } from './pages/login/login.component'
import { RequestsComponent } from './pages/requests/requests.component';
import { RequestDetailsComponent } from './pages/request-details/request-details.component';
import { UsersComponent } from './pages/users/users.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { DriversComponent } from './pages/drivers/drivers.component';
import { AddDriverComponent } from './pages/add-driver/add-driver.component';
import { WeightsComponent } from './pages/weights/weights.component';
import { HnResolver } from './helpers/services/app-resolvers.service';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AddNotificationsComponent } from './pages/add-notifications/add-notifications.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { FeedBackComponent } from './pages/feed-back/feed-back.component';
import { CitiesComponent } from './pages/cities/cities.component';
import { AddCityComponent } from './pages/add-city/add-city.component';
import { CharitiesComponent } from './pages/charities/charities.component';
import { AddCharityComponent } from './pages/add-charity/add-charity.component';
import { DriversListComponent } from './pages/drivers-list/drivers-list.component';
import { CountriesComponent } from './pages/countries/countries.component';
import { AddCountryComponent } from './pages/add-country/add-country.component';
import { DonationTypesComponent } from './pages/donation-types/donation-types.component';
import { AddDonationTypeComponent } from './pages/add-donation-type/add-donation-type.component';
import { TrucksLogComponent } from './pages/trucks-log/trucks-log.component';
import { AddTruckLogComponent } from './pages/add-truck-log/add-truck-log.component';

import { DonationBoxesListComponent } from './pages/donation-boxes/donation-boxes-list/donation-boxes-list.component';
import { AddDonationBoxComponent } from './pages/donation-boxes/add-donation-box/add-donation-box.component';
import { DonationBoxDetailsComponent } from './pages/donation-boxes/donation-box-details/donation-box-details.component';
import { FillDonationBoxCheckReportComponent } from './pages/donation-boxes/fill-donation-box-check-report/fill-donation-box-check-report.component';
import { FillDonationBoxMaintenanceReportComponent } from './pages/donation-boxes/fill-donation-box-maintenance-report/fill-donation-box-maintenance-report.component';
import { AddDonationBoxWeightRecordComponent } from './pages/donation-boxes/donation-box-weight-log/add-donation-box-weight-record/add-donation-box-weight-record.component';
import { DonationBoxWeightListComponent } from './pages/donation-boxes/donation-box-weight-log/donation-box-weight-list/donation-box-weight-list.component';
import { AddDonationBoxFinancialSettlementComponent } from './pages/donation-boxes/donation-box-weight-log/add-donation-box-financial-settlement/add-donation-box-financial-settlement.component';
import { DonationBoxCharityContractsListComponent } from './pages/donation-boxes/donation-box-charity-contracts-list/donation-box-charity-contracts-list.component';
import { DonationBoxFinancialSettlementsListComponent } from './pages/donation-boxes/donation-box-financial-settlements-list/donation-box-financial-settlements-list.component';
import { AddCharityContractComponent } from './pages/donation-boxes/add-charity-contract/add-charity-contract.component';
import { AddNewRequestComponent } from './pages/add-new-request/add-new-request.component';



const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'dashboard', component: HomeComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'country-requests'},{ expectedRole: 'country-weights' },  { expectedRole: 'admin-country' },{ expectedRole: 'charityUser' },{ expectedRole: 'accountant' }] },
  { path: 'requests', component: RequestsComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'user' },{expectedRole: 'admin-country'}, { expectedRole: 'admin' },{ expectedRole: 'country-requests' }] },
  { path: 'request-details/:reqId', component: RequestDetailsComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'user' },{expectedRole: 'admin-country'}, { expectedRole: 'admin' },{ expectedRole: 'country-requests' }] },
  { path: 'users', component: UsersComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'add-user', component: AddUserComponent, canActivate: [AuthGuardService], data: { expectedRole: 'admin' }, resolve: { data: HnResolver } },
  { path: 'edit-user/:id', component: AddUserComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'drivers', component: DriversComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }, { expectedRole: 'admin-country' }], },
  { path: 'drivers-list', component: DriversListComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }, { expectedRole: 'admin-country' }] },
  { path: 'add-driver', component: AddDriverComponent, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }, { expectedRole: 'admin-country' }], resolve: { data: HnResolver } },
  { path: 'edit-driver/:id', component: AddDriverComponent, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }, { expectedRole: 'admin-country' }], resolve: { data: HnResolver } },
  { path: 'weights', component: WeightsComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'user' },{expectedRole: 'admin-country'}, { expectedRole: 'admin' },{ expectedRole: 'country-requests' },{ expectedRole: 'country-weights' }] },
  { path: 'notifications', component: NotificationsComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'add-notifications', component: AddNotificationsComponent, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'customers', component: CustomersComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }, { expectedRole: 'admin-country' }]},
  { path: 'add-customer', component: AddCustomerComponent, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuardService], data: [{ expectedRole: 'user' },{expectedRole: 'admin-country'}, { expectedRole: 'admin' }] },
  { path: 'requests/:customerId', component: RequestsComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{expectedRole: 'admin-country'}] },
  { path: 'driver-requests/:DriverId', component: RequestsComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'customers-feedback', component: FeedBackComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'admin-country' }, { expectedRole: 'charityUser' }] },
  { path: 'cities', component: CitiesComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'add-city', component: AddCityComponent, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'edit-city/:id', component: AddCityComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'charities', component: CharitiesComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'add-charity', component: AddCharityComponent, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'edit-charity/:id', component: AddCharityComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'countries', component: CountriesComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'add-country', component: AddCountryComponent, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'edit-country/:id', component: AddCountryComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'donation-types', component: DonationTypesComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'add-donation-type', component: AddDonationTypeComponent, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'edit-donation-type/:id', component: AddDonationTypeComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: { expectedRole: 'admin' } },
  { path: 'trucks-log', component: TrucksLogComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'charityUser' },{ expectedRole: 'admin-country' }, { expectedRole: 'country-requests'},{ expectedRole: 'country-weights' },  { expectedRole: 'admin-country' } ] },
  { path: 'add-truck-log', component: AddTruckLogComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin-country' }, { expectedRole: 'country-requests'},{ expectedRole: 'country-weights' },  { expectedRole: 'admin-country' } ] },
  { path: 'edit-truck-log/:id', component: AddTruckLogComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }]},
  { path: 'donation-boxes-list', component: DonationBoxesListComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin'},{ expectedRole: 'charityUser'}, { expectedRole: 'donationBox'}] },
  { path: 'add-donation-box', component: AddDonationBoxComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }, { expectedRole: 'donationBox'}] },
  { path: 'edit-donation-box/:id', component: AddDonationBoxComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }, { expectedRole: 'donationBox'}] },
  { path: 'donation-box-details/:id', component: DonationBoxDetailsComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'donationBox'}] },
  { path: 'fill-donation-box-check-report/:id', component: FillDonationBoxCheckReportComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'donationBox'}] },
  { path: 'fill-donation-box-maintenance-report/:id', component: FillDonationBoxMaintenanceReportComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'donationBox'}] },
  { path: 'add-donation-box-weight-record', component: AddDonationBoxWeightRecordComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'inventory' }] },
  { path: 'donation-box-weight-list', component: DonationBoxWeightListComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'inventory' }] },
  { path: 'add-donation-box-financial-settlement', component: AddDonationBoxFinancialSettlementComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'accountant' }] },
  { path: 'donation-box-charity-contracts-list', component: DonationBoxCharityContractsListComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'accountant' }] },
  { path: 'donation-box-financial-settlements-list', component:DonationBoxFinancialSettlementsListComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'accountant' }] },
  { path: 'add-charity-contract', component:AddCharityContractComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' },{ expectedRole: 'accountant' }] },
  { path: 'add-new-request', component:AddNewRequestComponent, resolve: { data: HnResolver }, canActivate: [AuthGuardService], data: [{ expectedRole: 'admin' }] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    HnResolver
  ]
})
export class AppRoutingModule { }
