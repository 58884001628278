import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import { nameArValidator } from 'src/app/helpers/Validators/app-validators';

@Component({
  selector: 'app-add-city',
  templateUrl: './add-city.component.html',
  styleUrls: ['./add-city.component.scss']
})
export class AddCityComponent implements OnInit {
  response: any;
  cityDetail: any;
  pageHeading: any = 'Add New City';
  countryId: number;
  addCityForm: FormGroup;
  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    this.addCityForm = this.formBuilder.group({
      'nameEn': ['', Validators.compose([Validators.required])],
      'nameAr': ['', Validators.compose([Validators.required, nameArValidator])],
      'id': [''],
      'headId': ['', Validators.compose([Validators.required])],
    });
    if (this.route.url['value'][0].path == 'edit-city') {
      this.response = this.route.snapshot.data.data;
      this.pageHeading = 'Edit City';
      if (this.response.statusCode == 200) {
        this.cityDetail = this.response.result;
        this.setFormData();
      }
      else {
        this.globalMethodsService.loader.stopAll();
      }
    }
  }
  setFormData() {
    this.countryId = this.cityDetail[0].headId;
    this.addCityForm.controls['nameEn'].setValue(this.cityDetail[0].nameEn);
    this.addCityForm.controls['nameAr'].setValue(this.cityDetail[0].nameAr);
    this.addCityForm.controls['headId'].setValue(this.cityDetail[0].headId);
    this.addCityForm.controls['id'].setValue(this.cityDetail[0].id);
    this.globalMethodsService.loader.stopAll();
  }
  addCitySubmit() {
    if (this.addCityForm.valid) {
      this.globalMethodsService.loader.startBackground();
      this.addCityForm.value.headId = +this.addCityForm.value.headId;
      if (this.addCityForm.value.id == "") {
        this.addCityForm.value.id = 0;
      }
      else {
        this.addCityForm.value.id = +this.addCityForm.value.id
      }
      //debugger
      this.httpHelper.put('Common/addCity', this.addCityForm.value).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.router.navigateByUrl('/cities');
        }
        else if (this.response.statusCode == 409) {
          this.toastrService.error("City Name Already exist")
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
    }
  }

}
