import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { MatTableDataSource, TooltipPosition } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
export interface UserData {
  NameEn: string;
  NameAr: string;
  CountryNameEn: string;
  CountryNameAr: string;
  Edit: string;
}
@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {
  response: any;
  cities: any;
  responseDelete: any;
  countryid: number = 3;
  expendMenu: boolean = false;
  displayedColumns: string[] = ['NameEn', 'NameAr', 'CountryNameEn', 'CountryNameAr', 'Edit'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(public globalMethodsService: GlobalMethodsService,
    private route: ActivatedRoute,
    public httpHelper: HttpClientService) {
    window.scroll(0, 0);

    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      this.cities = this.response.result;
    }
    else {
      this.cities = [];
    }

    this.dataSource = new MatTableDataSource(this.cities);

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.globalMethodsService.loader.stopAll();
  }
  countrySelect(ev) {
    this.countryid = +ev.currentTarget.value;
    this.globalMethodsService.loader.startBackground();
    this.httpHelper.getWithAuthToken('Common/getCities?countryId=' + this.countryid+'&active=A').then(result => {
      this.response = result;

      if (this.response.result.length > 0) {
        this.cities = this.response.result;
        this.dataSource = new MatTableDataSource(this.cities);
        this.globalMethodsService.loader.stopAll();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
      }
      else {
        this.cities = [];
        this.dataSource = new MatTableDataSource(this.cities);
        this.globalMethodsService.loader.stopAll();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

      // this.dwsTransactions = this.byDriver;

    })


  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onChange(id, ev) {
    //debugger
    let cityActive = {
      "Id": id,
      "isActive": ev.checked,
    }

    this.globalMethodsService.loader.startBackground();
    this.httpHelper.put('Common/ActiveMasterData', cityActive).then(result => {
      this.globalMethodsService.loader.stopBackground();
      this.responseDelete = result;
      if (this.responseDelete.statusCode == 200) {
        this.globalMethodsService.loader.stopBackground();
      }
      else {
        this.globalMethodsService.loader.stopBackground();
        this.globalMethodsService.showErrorToast();
      }
    });
  }
}
