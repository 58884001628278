import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { dateLessThan } from 'src/app/helpers/Validators/app-validators';
import * as _moment from 'moment';
import { defaultFormat as _rollupMoment } from 'moment';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import Swal from 'sweetalert2'
export interface UserData {
  driver: string;
  entryDate: string;
  totalWeight: string;
}


@Component({
  selector: 'app-weights',
  templateUrl: './trucks-log.component.html',
  styleUrls: ['./trucks-log.component.scss'],

})
export class TrucksLogComponent implements OnInit {
  drivers: any;
  response: any;
  charities: any;
  filterByDateForm: FormGroup;
  displayedColumns: string[] = ['driver', 'entryDate', 'totalWeight','tools'];
  dataSource: MatTableDataSource<UserData>;
  countryAllSelected: boolean = true;
  countryid = 0;
  charityId = 0;
  country: any = "A";
  otherUser:boolean=false;
  charityAllSelected: boolean = true;
  startDate = new Date();
  endDate = new Date();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public globalMethodsService: GlobalMethodsService,
    private route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder,
    public changeDetectorRefs: ChangeDetectorRef,
    public baseService: BaseService, public validationMessages: ValidationMessagesService) {
    window.scroll(0, 0);
    if (this.baseService.getLocalStorageItem('userObj').country != null) {
      this.country = this.baseService.getLocalStorageItem('userObj').country;
    }
    this.startDate = this.globalMethodsService.setFromDate(this.startDate, -3);

    //this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    //debugger
    if (this.response.statusCode == 200) {
      this.drivers = this.response.result;
    
    }
    else { this.drivers = []; }
    this.dataSource = new MatTableDataSource(this.drivers);
    /*if (this.country != "A") {
      this.countryid = this.globalMethodsService.countries.find(res => res.countryCode == this.country).id;
     // this.country = this.globalMethodsService.countries[0].countryCode;
    }
    if(this.baseService.getLocalStorageItem('userObj').role=='user'){
      this.otherUser=true;
      this.countryAllSelected=false;
    }
    else { this.countryid = 0; }
    this.getCharities();*/
    this.globalMethodsService.loader.stopAll();
  }
  // addWeight(row) {
  //   //debugger

  //   let date = this.baseService.setToDate(this.filterByDateForm.value.startDate);
  //   date = JSON.stringify(new Date(date));
  //   if (this.addWeightForm.value.weight != "") {
  //     this.globalMethodsService.loader.startBackground();
  //     let object = {
  //       entryDate: JSON.parse(date),
  //       driverId: row.id,
  //       weight: this.addWeightForm.value.weight,
  //     }
  //     this.httpHelper.post('Drivers/insertLog', object).then(result => {
  //       this.response = result;
  //       this.globalMethodsService.loader.stopAll();
  //       //debugger
  //       if (this.response.statusCode == 200) {
  //         //debugger
  //       }
  //       else {
  //         //debugger
  //         this.globalMethodsService.showErrorToast();
  //       }
  //     });
  //   }
  // }
  ngOnInit() {
    this.filterByDateForm = this.formBuilder.group({
      'startDate': [this.startDate, Validators.compose([Validators.required])],
      'endDate': [this.endDate, Validators.compose([Validators.required])]
    }, { validator: dateLessThan('startDate', 'endDate') });

    /*this.filterByDateForm.get("endDate").valueChanges.subscribe(x => {
      setTimeout(() => {
        this.filterByDate();
      })
    });
    this.filterByDateForm.get("startDate").valueChanges.subscribe(x => {
      setTimeout(() => {
        this.filterByDate();
      })

    });*/

    this.dataSource.paginator = this.paginator;
    
    this.dataSource.sort = this.sort;
    if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country' || this.baseService.getLocalStorageItem('userObj').role == 'country-requests') {
      console.log("ddfsdfsf")
      this.countrySelectLoad();
      
    }
  }

  showSweetAlert(row,action) {
    let text='';
    if(action=='duplicate')
    {
      text='This request will be marked as duplicate/test'
    }
    else if(action == 'noAnswer'){
      text="This request will be marked as no answer"
    }else if(action='delete'){
      text='This request will be deleted!'
    }
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value && (action == 'duplicate' || action == 'noAnswer')) {
        
      }else if(result.value && action == 'delete'){
        this.markAsDeleted(row,action);
      }
    })
  }

  
  markAsDeleted(row,action){
    let data={
      Id:row.id,
      modifiedBy:this.baseService.getLocalStorageItem('userObj').id,
      ModifiedAt:new Date(),
      IsDeleted:true
    }
  
    this.httpHelper.put('TruckWeight/delete', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      
      if (this.response.statusCode == 200) {
        
      }
      else {
        
        this.globalMethodsService.showErrorToast();
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  createString(arr, key) {
    return arr.map(function (obj) {
      return "" + obj[key] + "";
    }).join(', ');
  }

  calculateTotalWeight(){
    var sum = 0;
    Object.keys(this.drivers).forEach(key => {
      sum = sum + this.drivers[key].totalWeight;
    })

    return sum;
  }

  filterByDate() {
    if (this.filterByDateForm.valid) {
      //debugger
      /*this.globalMethodsService.loader.startBackground();
      var startDateDiscrete = this.filterByDateForm.value.startDate.split('-');
      var endDateDiscrete = this.filterByDateForm.value.endDate.split('-');
      
      var startDateObj = new Date(startDateDiscrete[0], startDateDiscrete[1], startDateDiscrete[2]);
      var endDateObj = new Date(endDateDiscrete[0], endDateDiscrete[1], endDateDiscrete[2]);*/

     
      //this.filterByDateForm.value.startDate = this.baseService.setToDatePlusOneDay(this.filterByDateForm.value.startDate);
      //this.filterByDateForm.value.endDate = this.baseService.setToDatePlusOneDay(this.filterByDateForm.value.endDate);

      this.filterByDateForm.value.startDate = this.baseService.setToDate(this.filterByDateForm.value.startDate);
      this.filterByDateForm.value.endDate = this.baseService.setToDate(this.filterByDateForm.value.endDate);

      //var startDateExtra = Date.parse(this.filterByDateForm.value.startDate,);
      let data = {
        fromDate: this.filterByDateForm.value.startDate,
        toDate: this.filterByDateForm.value.endDate,

      }

  
      this.httpHelper.getWithAuthToken('TruckWeight/getTrucksWeights?fromDate=' + data.fromDate + '&toDate='+data.toDate).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.drivers = this.response.result;
          //console.log(this.drivers);
          this.dataSource = new MatTableDataSource(this.drivers);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else { this.drivers = []; }
      });

      //debugger



    }
    else {
      this.validationMessages.validateAllFormFields(this.filterByDateForm);
    }
  }
  countrySelect(ev) {
    this.countryid = +ev.currentTarget.value;
    if (this.countryid != 0) {
      this.country = this.globalMethodsService.countries.find(res => res.id == this.countryid).countryCode;
      this.countryAllSelected = false;
      //  this.filterByDate();
    }
    else {
      this.countryAllSelected = true;
      this.country = "A";
      //this.filterByDate();
    }
    this.getCharities();
    this.filterByDate();
  }

  countrySelectLoad() {
    this.countryid = 1;
    if (this.countryid != 0) {
      this.country = 'QTR';
      this.countryAllSelected = false;
      //  this.filterByDate();
    }
   // this.getCharities();
    this.filterByDate();
  }
  getCharities() {
    let data = {
      countryCode: this.country
    };
    this.httpHelper.put('Common/getCharities', data).then(result => {
      this.response = result;
      //this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
        //debugger
        //console.log(this.charities);
        this.charityId = 0;
      }
      else { this.charities = []; }
      // this.dwsTransactions = this.byDriver;
    });
  }
  charityChange(ev) {
    this.charityId = +ev.currentTarget.value;
    if (this.charityId == 0) {
      this.charityAllSelected = true;
    }
    else { this.charityAllSelected = false; }
    this.filterByDate();
  }
}
