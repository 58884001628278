import { Component, OnInit } from '@angular/core';
import { InteractionMode } from "igniteui-angular";
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { phoneNumberValidator } from 'src/app/helpers/Validators/app-validators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.scss']
})
export class AddDriverComponent implements OnInit {

  toppings = new FormControl();
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  addDriverForm: FormGroup;
  public mode: InteractionMode = InteractionMode.DropDown;
  response: any;
  userDetail: any;
  pageHeading: any = 'Add New Driver';
  cities: any;
  countryId: number;
  cityDisabled: boolean = true;
  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService, public route: ActivatedRoute, public toastrService: ToastrService) {
    window.scroll(0, 0);
    this.globalMethodsService.loader.stopAll();
    //debugger
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      if (this.route.url['value'][0].path == 'edit-driver') {
        this.pageHeading = 'Edit Driver';
        let userId = this.route.url['value'][1].path;
        this.getuserDetail(userId);
      }
      this.cities = this.response.result;
    }
    else {
      this.cities = [];
    }
  }

  ngOnInit() {
    this.addDriverForm = this.formBuilder.group({
      'name': ['', Validators.compose([Validators.required])],
      'mobile': ['', Validators.compose([Validators.required, phoneNumberValidator])],
      'vehicleNo': ['', Validators.compose([Validators.required])],
      'Id': [''],
      'countryId': ['', Validators.compose([Validators.required])],
      'city': [[], Validators.compose([Validators.required])],
    });
  }
  addDriverSubmit() {
    //debugger
    this.addDriverForm.controls.city.markAsTouched();
    this.addDriverForm.controls.city.markAsDirty();
    if (this.addDriverForm.valid) {
      this.addDriverForm.value.mobile = this.parseArabic(this.addDriverForm.value.mobile)
      this.globalMethodsService.loader.startBackground();
      this.addDriverForm.value.countryId=+this.addDriverForm.value.countryId;
      if (this.route.url['value'][0].path == 'edit-driver') {
        debugger
        let updateData = {
          Id: this.addDriverForm.value.Id,
          name: this.addDriverForm.value.name,
          vehicleNo: this.addDriverForm.value.vehicleNo,
          city: this.addDriverForm.value.city,
          countryId: +this.addDriverForm.value.countryId,
          mobile:this.addDriverForm.value.mobile

        }
        this.httpHelper.put('Drivers/update', updateData).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/drivers');
          }
          else {
            this.globalMethodsService.showErrorToast();
          }
        });
      }
      else {
        this.httpHelper.put('Drivers/add', this.addDriverForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          //debugger
          if (this.response.statusCode == 200) {
            //debugger
            this.router.navigateByUrl('/drivers');
          }
          else if (this.response.statusCode == 409) {
            this.toastrService.error("Mobile Number Already exist")
          }
          else {
            //debugger
            this.globalMethodsService.showErrorToast();
          }
        });
      }
    }
    else {
      this.validationMessages.validateAllFormFields(this.addDriverForm);
    }
  }
  getuserDetail(id) {
    this.httpHelper.getWithAuthToken('Drivers/' + id).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.statusCode == 200) {
        this.userDetail = this.response.result;
        this.setFormData();
      }

    });
  }
  setFormData() {
    this.countryId=this.userDetail.country.id;
    this.getCities();
    this.cityDisabled=false;
    this.addDriverForm.controls['name'].setValue(this.userDetail.name);
    this.addDriverForm.controls['vehicleNo'].setValue(this.userDetail.vehicleNo);
    this.addDriverForm.controls['mobile'].setValue(this.userDetail.mobile);
    this.addDriverForm.controls['Id'].setValue(this.userDetail.id);
    this.addDriverForm.controls['countryId'].setValue(this.userDetail.country.id);
    this.addDriverForm.controls['city'].setValue(this.userDetail.city.map(res => res.id));
  }
  parseArabic(str) {
    //debugger
    return String(str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
      return d.charCodeAt(0) - 1632;
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
      return d.charCodeAt(0) - 1776;
    }));
  }
  countrySelect(ev) {

    this.countryId = +ev.currentTarget.value;
    this.getCities();
  }
  getCities() {
    this.globalMethodsService.loader.startBackground();
    this.httpHelper.getWithAuthToken('Common/getCities?countryId=' + this.countryId).then(result => {
      this.response = result;

      if (this.response.result.length > 0) {
        this.cities = this.response.result;
        this.cityDisabled = false;
        //console.log(this.cities);
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }
      else {
        this.cities = [];
        this.cityDisabled = true;
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }

    })
  }
}
