import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import LocationPicker from "location-picker";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EnvService } from 'src/app/helpers/environment/env.service';
import { AppComponent } from 'src/app/app.component';


@Component({
  selector: 'app-fill-donation-box-check-report',
  templateUrl: './fill-donation-box-check-report.component.html',
  styleUrls: ['./fill-donation-box-check-report.component.scss']
})
export class FillDonationBoxCheckReportComponent implements OnInit {
  response: any;
  donationBoxDetail: any;
  pageHeading: any = 'ملء التقرير الدوري لصندوق التبرع';
  fillDonationBoxCheckReportForm: FormGroup;
  donationBoxDetails: any;
  baseURL:any;
  lp: LocationPicker;
  public progress: string;
  public progress1: string;
  public progress2: string;
  public progress3: string;
  url = '';
  firstAttachment = '';
  secondAttachment = '';
  thirdAttachment = '';
  fileName: string = 'Choose File';
  fileName1: string = '';
  fileName2: string = '';
  fileName3: string = '';
  reporterLocation: string;

  constructor(public formBuilder: FormBuilder,
    public appComp: AppComponent,
    public baseService: BaseService,
    private http: HttpClient,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    private env: EnvService,
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    this.baseURL = this.env.apiUrl;
    this.baseService.createLocalStorageItem('rUrl', "");
    window.scroll(0, 0);
    }
  ngOnInit() {
    this.response = this.route.snapshot.data.data;
    this.donationBoxDetails = this.response.result;

    let location = this.donationBoxDetails[0].location.split(",");
    this.lp = new LocationPicker('map', {
      lat: location[0], lng: location[1]
    });

    this.appComp.showHeader = true;
  

    this.fillDonationBoxCheckReportForm = this.formBuilder.group({
      'donationBoxId': ['', Validators.compose([Validators.required])],
      'fullnessLevel': ['', Validators.compose([Validators.required])],
      'notes': ['', Validators.compose([Validators.required])],
      'reporterId': ['', Validators.compose([Validators.required])],
      'reporterLocation': ['', Validators.compose([Validators.required])],
      'firstAttachment': ['', Validators.compose([Validators.required])],
      'secondAttachment': ['', Validators.compose([Validators.required])],
      'thirdAttachment': ['', Validators.compose([Validators.required])],
      'createdBy':['']
    });

    this.fillDonationBoxCheckReportForm.controls['reporterId'].setValue (1);
    this.fillDonationBoxCheckReportForm.controls['donationBoxId'].setValue (this.donationBoxDetails[0].id);
    this.fillDonationBoxCheckReportForm.controls['reporterLocation'].setValue (this.donationBoxDetails[0].location);

    // Get current location of the reporter
    navigator.geolocation.getCurrentPosition((position) => { 
      this.reporterLocation = position.coords.latitude.toString() + "," + position.coords.longitude.toString(); 
 
    });

    this.globalMethodsService.loader.stopAll();

  }
  setFormData() {
    this.fillDonationBoxCheckReportForm.controls['fullnessLevel'].setValue(this.donationBoxDetail[0].fullnessLevel);
    this.fillDonationBoxCheckReportForm.controls['notes'].setValue(this.donationBoxDetail[0].notes);
    this.fillDonationBoxCheckReportForm.controls['reporterId'].setValue(this.donationBoxDetail[0].city.id);
    this.fillDonationBoxCheckReportForm.controls['donationBoxId'].setValue(this.donationBoxDetail[0].donationBoxId);
    this.fillDonationBoxCheckReportForm.controls['reporterLocation'].setValue(this.reporterLocation);
    this.fillDonationBoxCheckReportForm.controls['firstAttachment'].setValue(this.firstAttachment);
    this.fillDonationBoxCheckReportForm.controls['secondAttachment'].setValue(this.secondAttachment);
    this.fillDonationBoxCheckReportForm.controls['thirdAttachment'].setValue(this.thirdAttachment);

    this.globalMethodsService.loader.stopAll();
  }

  fillDonationBoxCheckReportFormSubmit() {

    if (this.fillDonationBoxCheckReportForm.valid) {
      this.globalMethodsService.loader.startBackground();
      this.fillDonationBoxCheckReportForm.controls['createdBy'].setValue(this.baseService.getLocalStorageItem('userObj').id);
        this.httpHelper.put('DonationBoxes/addDonationBoxCheckReport', this.fillDonationBoxCheckReportForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/donation-boxes-list');
          }
          else if (this.response.statusCode == 409) {
            this.toastrService.error("Box exist already")
          }
          else {
            this.globalMethodsService.showErrorToast();
          }
        });
      

    }
  }

  onSelectFile(event, pictureId) {
    if (event.target.files && event.target.files[0]) {
      let fileToUpload = <File>event.target.files[0];
      
      if (fileToUpload.type.split('/')[0].toLowerCase() != 'image') {
        this.fillDonationBoxCheckReportForm.controls.charityLogo.setErrors({ 'invalidType': true });
      }
      else {
        this.fileName=fileToUpload.name;
        if(pictureId == 1){
          this.fileName1 = fileToUpload.name;
        }else if (pictureId == 2){
          this.fileName2 = fileToUpload.name;
        }else if (pictureId == 3){
          this.fileName3 = fileToUpload.name;
        }
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        this.http.put(this.baseURL + 'Common/uploadFile', formData, { reportProgress: true, observe: 'events' }).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress){
            if(pictureId == 1){
              this.progress1 = String(Math.round(100 * event.loaded / event.total)) + '%';
            }else if (pictureId == 2){
              this.progress2 = String(Math.round(100 * event.loaded / event.total)) + '%';
            }else if (pictureId == 3){
              this.progress3 = String(Math.round(100 * event.loaded / event.total)) + '%';
            }
           }else if (event.type === HttpEventType.Response) {
            this.response = event.body;
            if (this.response.statusCode == 200) {
              if(pictureId == 1){
                this.firstAttachment = this.response.url;
                this.fillDonationBoxCheckReportForm.controls['firstAttachment'].setValue(this.response.url);
                this.progress1 = '';
              }else if (pictureId == 2){
                this.secondAttachment = this.response.url;
                this.fillDonationBoxCheckReportForm.controls['secondAttachment'].setValue(this.response.url);
                this.progress2 = '';
              }else if (pictureId == 3){
                this.thirdAttachment = this.response.url;
                this.fillDonationBoxCheckReportForm.controls['thirdAttachment'].setValue(this.response.url);
                this.progress3 = '';
              }

              this.url = this.response.url;
              
              //this.fillDonationBoxCheckReportForm.controls['attachmentId'].setValue(this.response.attachmentId);
            }
            else {
              this.globalMethodsService.showErrorToast();
              //this.fillDonationBoxCheckReportForm.controls['attachmentId'].setValue('');
            }
          }
        });
      }
    }
  }
}
