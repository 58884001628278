import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { InteractionMode } from "igniteui-angular";
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Observable } from 'rxjs';
import { map, isEmpty } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/helpers/common/base.service';
import { stringify } from 'querystring';
import { Lightbox } from 'ngx-lightbox';
declare var google;
@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss']
})
export class RequestDetailsComponent implements OnInit, AfterViewInit {
  requestDetails: any;
  response: any;
  stage: string;
  minDate = new Date();
  minTime = new Date();
  UserRequestDetails: FormGroup;
  updateRequestForm: FormGroup;
  driversResponse: Observable<any>;
  statusesResponse: Observable<any>;
  public mode: InteractionMode = InteractionMode.DropDown;
  public format: string = "hh:mm tt";
  public date: Date;
  public time: Date;
  donationTypes = [{
    "id": 22,
    "nameEn": "Clothes"
  }, {
    "id": 23,
    "nameEn": "Electronic Devices"
  }, {
    "id": 24,
    "nameEn": "Furniture"
  }];
  pickUpDate;
  pickUpTime;
  map;
  @ViewChild('mapElement', { static: true }) mapElement;
  latitude;
  longtituede;
  constructor(public httpService: HttpClientService,
    public validations: ValidationMessagesService,
    public globalService: GlobalMethodsService,
    public router: Router,
    public appComponent: AppComponent,
    private route: ActivatedRoute, public formBuilder: FormBuilder, public toastrService: ToastrService,
    public baseService: BaseService, public el: ElementRef,
    public _lightbox: Lightbox) {

    window.scroll(0, 0);
    //debugger
    this.minDate.setDate(this.minDate.getDate());
    this.minTime = new Date();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      this.requestDetails = this.response.result;
      if (this.requestDetails.attachments && this.requestDetails.attachments.length > 0) {
        this.requestDetails.attachments.forEach(element => {
          element['src'] = element.filePath;
          element['thumb'] = element.filePath;

        });
      }
      var removeBraces = this.requestDetails.pickupLocation.replace(/[{}]/g, "");
      var location = removeBraces.split(',');
      this.latitude = Number(location[0]);
      this.longtituede = Number(location[1]);
      if (this.requestDetails.pickupAt) {
        this.date = new Date(this.requestDetails.pickupAt);
        this.time = new Date(this.requestDetails.pickupAt);
      }
    }
    else { this.requestDetails = null; }
    this.getDrivers();

  }
  ngOnInit() {
    //this.getDonationTypes();
    //debugger
    this.getStatus();
    //debugger
    this.updateRequestForm = this.formBuilder.group({
      'date': [this.date, Validators.compose([Validators.required])],
      'time': [this.requestDetails.status == 'New Request' ? '' : this.time, Validators.compose([Validators.required])],
      'driverId': [this.requestDetails.driverId ? this.requestDetails.driverId : '', Validators.compose([Validators.required])],
      'remarks': [this.requestDetails.remarks],
      'status': [this.requestDetails.status == 'New Request' ? 'P' : this.requestDetails.status == 'In Progress' ? 'P' : 'C', Validators.compose([Validators.required])],
      'Id': [this.requestDetails.id],
      'modifiedBy': [this.baseService.getLocalStorageItem('userObj').id],
      'weight': [this.requestDetails.weight ? this.requestDetails.weight : 0],
      'pickupAt': [''],
      'CommentForCustomer': [this.requestDetails.commentForCustomer ? this.requestDetails.commentForCustomer : '']
    });
    const control = this.donationTypes.map((d) => new FormControl());

    this.UserRequestDetails = this.formBuilder.group({
      'donationType': this.formBuilder.array(control, this.checkboxValidator)
    });
    if (this.baseService.getLocalStorageItem('userObj').role == 'user' && this.requestDetails.status == 'Completed') {
      this.updateRequestForm.disable();
    }
    this.setFormData();
    this.updateRequestForm.controls['status'].valueChanges.subscribe(res => {
      //debugger
      if (res == 'C') {
        this.updateRequestForm.controls['weight'].setValidators([Validators.required,Validators.min(0)]);
        this.updateRequestForm.controls['weight'].updateValueAndValidity();
      }
      else {
        this.updateRequestForm.controls['weight'].clearValidators();
        this.updateRequestForm.controls['weight'].updateValueAndValidity();
      }

    });

  }
  ngAfterViewInit() {
    this.showMap();
  }
  showMap() {
    //debugger
    this.map = new google.maps.Map(
      this.mapElement.nativeElement,
      {
        center: { lat: this.latitude, lng: this.longtituede },
        zoom: 12,
      });
    this.addMarker(this.map);
  }

  addMarker(map: any) {
    //debugger
    let marker = new google.maps.Marker({
      map: map,
      animation: google.maps.Animation.DROP,
      position: map.getCenter()
    });
  }
  getDrivers() {
    let cityId = []
    cityId.push(this.requestDetails.cityId);
    //debugger
    this.driversResponse = this.httpService.postWithAuthToken2('Drivers/byCity', cityId).pipe(map(resp => resp["result"]));
  }
  getStatus() {
    this.statusesResponse = this.httpService.getWithAuthToken2('Common/status').pipe(map(resp => resp["result"]));
    this.globalService.loader.stopAll();
  }
  getDonationTypes() {
    this.httpService.getWithAuthToken('Common/getDonationTypes').then(result => {
      this.response = result;
      this.globalService.loader.stopAll();
      if (this.response.statusCode == 200) {
        //debugger
        this.donationTypes = this.response.result;
        //console.log(this.donationTypes);

      }
      else {
        this.donationTypes = [];
      }

    });
  }
  addInfoWindow(marker, content) {
    let infoWindow = new google.maps.InfoWindow({
      content: content
    });
    google.maps.event.addListener(marker, 'click', () => {
      infoWindow.open(this.map, marker);
    });
  }
  updateRequest() {
    if (this.updateRequestForm.valid) {
      //debugger
      let pickupAt = this.convertDateTime(this.updateRequestForm.value.date, this.updateRequestForm.value.time);
      //debugger
      this.updateRequestForm.value.pickupAt = pickupAt;
      this.globalService.loader.startBackground();
      //debugger
      let postValues = {
        Id: this.updateRequestForm.value.Id,
        driverId: parseInt(this.updateRequestForm.value.driverId),
        pickupAt: this.updateRequestForm.value.pickupAt,
        remarks: this.updateRequestForm.value.remarks,
        status: this.updateRequestForm.value.status,
        commentForCustomer: this.updateRequestForm.value.CommentForCustomer,
        modifiedBy: this.updateRequestForm.value.modifiedBy,
        modifiedAt: this.convertDateTime(new Date(), new Date()),
        clientAppId: '',
        cityId: this.requestDetails.cityId,
        AssociationId: this.requestDetails.associationId,
        customerId: 0,
        weight: this.updateRequestForm.value.weight
      }
      //debugger
      if (this.updateRequestForm.value.status == 'C' && this.requestDetails.status != "Completed" && (!!this.requestDetails.clientAppId)) {
        postValues.customerId = this.requestDetails.customerId;
        postValues.clientAppId = this.requestDetails.clientAppId;
      }
      else {
        delete (postValues.clientAppId);
      }
      this.httpService.put('Requests/update', postValues).then(result => {
        this.response = result;
        this.globalService.loader.stopAll();
        //debugger
        if (this.response.statusCode == 200) {

          this.router.navigateByUrl('/requests');
        }
        else {
          //debugger
          this.globalService.showErrorToast();
        }
      });
    }
    else {
      this.validations.validateAllFormFields(this.updateRequestForm);
    }
  }
  convertDateTime(day, getTime) {
    let date;
    if (day._d) {
      date = this.convertDate(day._d);
    }
    else {
      date = this.convertDate(day);
    }
    let time = this.convertTime(getTime);
    this.updateRequestForm.value.date
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM.toLowerCase() == "pm" && hours < 12) hours = hours + 12;
    if (AMPM.toLowerCase() == "am" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    time = sHours + ":" + sMinutes + ":00";
    var newDate = date + "T" + time;
    return newDate;
  }
  convertDate(date) {
    var dd = date.getDate();
    if (dd < 10) {
      dd = ('0' + dd).slice(-2);
    }
    var mm = date.getMonth() + 1;
    if (mm < 10) {
      mm = ('0' + mm)
    }
    var yyyy = date.getFullYear();
    date = yyyy + '-' + mm + '-' + dd;
    return (date);
  }
  convertTime(getTime) {
    return getTime.value = new Date(getTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
  copyInputMessage() {
    if (this.updateRequestForm.controls.date.valid && this.updateRequestForm.controls.time.valid) {
      const from = document.getElementById('message');
      const range = document.createRange();
      window.getSelection().removeAllRanges();
      range.selectNode(from);
      window.getSelection().addRange(range);

      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      this.toastrService.success("Message Copied To Clipboard");
    }
    else {
      let invalidControl;
      if (!(this.updateRequestForm.controls.date.valid)) {
        //debugger
        invalidControl = this.el.nativeElement.querySelector('.copyMessageDateValid');
        this.validations.validateAllFormFields(this.updateRequestForm);
      }
      else {
        this.validations.validateAllFormFields(this.updateRequestForm);
        invalidControl = this.el.nativeElement.querySelector('.copyMessageDateValid');
      }
      if (invalidControl) {
        invalidControl.focus();
      }
    }
  }
  open(index: number): void {
    // open lightbox
    this._lightbox.open(this.requestDetails.attachments, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
  get getCountOfCheckboxes() {

    return this.UserRequestDetails.get('donationType')['controls'];
  }
  checkboxValidator(control: AbstractControl) {
    return control.value.indexOf(true) === -1 ? { 'checkboxSelectionError': true } : null;
  }
  setFormData() {
    var arr = [];
    this.donationTypes.forEach(element => {
      //debugger
      if (this.requestDetails.donatType.find(x => x == element.id)) {
        arr.push(true)
      }
      else {
        arr.push(false);
      }
    });

    this.UserRequestDetails.controls['donationType'].setValue(arr);
  }
  // changeStatus(ev)
  // {
  //   //debugger
  //   if(ev.currentTarget.value){
  //     this.updateRequestForm.controls.weight    
  //   }
  // }
}
