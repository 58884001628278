import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';

export interface UserData {
  referenceNumber: string;
  englishNotification: string;
  arabicNotification: string;
  dateTime: string;
}
/**
* @title Data table with sorting, pagination, and filtering.
*/

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  response: any;
  notifications: any;
  countryid:number=0;
  countryAllSelected:boolean=true;
  expendMenu:boolean=false;
  displayedColumns: string[] = ['referenceNumber', 'country', 'englishNotification', 'arabicNotification', 'dateTime'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public globalMethodsService: GlobalMethodsService,
     private route: ActivatedRoute,
     public httpHelper:HttpClientService ) {
    window.scroll(0, 0);
    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      this.notifications = this.response.result;
    }
    else {
      this.notifications = [];
    }

    this.dataSource = new MatTableDataSource(this.notifications);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  countrySelect(ev) {
    this.globalMethodsService.loader.startBackground();
    this.countryid = +ev.currentTarget.value;
    if (this.countryid != 0) {
      this.countryAllSelected = false;
    }
    else { this.countryAllSelected = true; }
    this.getNotifications();
  }
  getNotifications(){
    this.httpHelper.getWithAuthToken('Notification?countryId='+this.countryid).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.notifications = this.response.result;
        //console.log(this.notifications);
      }
      else {  this.notifications = []; }
      this.dataSource = new MatTableDataSource(this.notifications);
    });
  }
}
