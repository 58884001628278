import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { MatTableDataSource, TooltipPosition } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { BaseService } from 'src/app/helpers/common/base.service';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';

declare var google: any;
export interface UserData {
  id: string,
  name: string,
  'city.nameAr': string,
  'chairty.nameAr': string
}

@Component({
  selector: 'app-donation-boxes',
  templateUrl: './donation-boxes-list.component.html',
  styleUrls: ['./donation-boxes-list.component.scss']
})
export class DonationBoxesListComponent implements OnInit {
  response: any;
  showMap: boolean = true;
  donationBoxes: any;
  charityAllSelected: any;
  charities: any;
  selectedCharityId: Number;
  displayedColumns: string[] = ['id', 'name', 'city.nameAr', 'city.nameEn','charity.nameAr', 'lastCheckReport', 'lastMaintenanceReport', 'productivityChange', 'isActive', 'tools'];
  dataSource: MatTableDataSource<UserData>;
  map: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('map', {static: false}) mapElement:any;

  markers = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(public globalMethodsService: GlobalMethodsService,
    globalService: GlobalMethodsService,
    public translate: TranslateService,
    public appComp: AppComponent,
    public baseService: BaseService,

    private route: ActivatedRoute,
    public httpHelper: HttpClientService) {
    window.scroll(0, 0);
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {

      this.donationBoxes = this.response.result;
      this.dataSource = new MatTableDataSource(this.donationBoxes);

      if(this.globalMethodsService.userObj.role == "charityUser"){
        this.displayedColumns = ['id', 'name', 'city.nameAr', 'charity.nameAr', 'lastCheckReport', 'lastMaintenanceReport', 'tools'];
      }

    }
    this.setupLang();
    // on default languge change, change the current language for validation messages
    translate.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
      this.setupLang();
    });

  }

  setupLang(){
    let currentLang = this.baseService.getLocalStorageItem('lang');
    
    if(currentLang == "en"){
      this.displayedColumns = ['id', 'name', 'city.nameEn','charity.nameEn', 'lastCheckReport', 'lastMaintenanceReport', 'productivityChange', 'isActive', 'tools'];

    }else if(currentLang == "ar"){
      this.displayedColumns = ['id', 'name', 'city.nameAr','charity.nameAr', 'lastCheckReport', 'lastMaintenanceReport', 'productivityChange', 'isActive', 'tools'];

    }
  }

  ngOnInit() {
    // get list of active charities in Oman
    this.getCharities();
    this.setSortingDataAccessorForNestedObjects();
    this.extractDonationBoxesLocations();
    this.globalMethodsService.loader.stopAll();
  }

  ngAfterViewInit(): void {
    this.appComp.showHeader = true;
    this.loadMap();
    
  }

  /**
   * Allow nested objects to be accessed for sorting in the table.
   */
  setSortingDataAccessorForNestedObjects() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      // if the property includes (.), means it's nested field in JSON object
      if (property.includes('.')) {
        const accessor = property.split('.');
        let value: any = item;
        accessor.forEach((a) => {
          value = value[a];
        });

        // return the value of the nested object
        return value;
      }

      // if it is not nested field, then return the value as-is
      return item[property];
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onToggle(id, ev) {
    let data = {
      "id": id,
      "isActive": ev.checked,
    }

    this.globalMethodsService.loader.startBackground();
    this.httpHelper.put('DonationBoxes/activateDisableDonationBox', data).then(result => {
      this.globalMethodsService.loader.stopBackground();
      this.response = result;
      if (this.response.statusCode == 200) {
        this.globalMethodsService.loader.stopBackground();
      }
      else {
        this.globalMethodsService.loader.stopBackground();
        this.globalMethodsService.showErrorToast();
      }
    });
  }

  getCharities() {
    let data = {
      countryCode: 'OMN'
    };

    this.httpHelper.put('Common/getDonationBoxesCharities', data).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
      }
      else {
        this.charities = [];
      }
    });
  }

  charityChange(ev) {
    this.selectedCharityId = +ev.currentTarget.value;
    if (this.selectedCharityId != 0) {
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxesByCharityId?charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });

    } else if (this.selectedCharityId == 0) { // if all chariites selected
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxes').then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        this.loadDonationBoxesInTable();

      });
    }


  }

  /**
   * Load dontation boxes data in the table.
   */
  loadDonationBoxesInTable(){
    // if there is data, load them immediately
    if (this.response.result.length > 0) {
      this.donationBoxes = this.response.result;
      this.dataSource = new MatTableDataSource(this.donationBoxes);
      this.setSortingDataAccessorForNestedObjects();
      this.extractDonationBoxesLocations()
      this.loadMap();

      
    } else {
      this.donationBoxes = [];
      this.showMap = false;
      this.dataSource = new MatTableDataSource(this.donationBoxes);
    }

  }

/**
 * Extract locations from donation boxes and add them to the map as markers
 */
  extractDonationBoxesLocations(){
    // empty the list of markers
    this.markers = [];

    // iterate through donation boxes
    for(let donationBox of this.donationBoxes){

      // if donation box is active add it to the map markers
      if(donationBox.isActive == true){
        // split the location field by comma to extract lat and lng
        let location = donationBox.location.split(',');

        // prepare location object along with the name of the location
        let locationObject = {lat: location[0], lng: location[1],label:donationBox.id + " - " + donationBox.name };

        // add the location object to the markers list
        this.markers.push(locationObject);
      }
    }

  }

  /**
   * Load google map and show the markers.
   */
  loadMap(){

    // if only there are markers show the map.
    this.showMap = true;
    if(this.markers.length > 0){
      const mapProperties = {
        center: new google.maps.LatLng(this.markers[0].lat,this.markers[0].lng),
        zoom: 8,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
      this.markers.forEach(location => {
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.lat, location.lng),
          map: this.map,
          title:location.label
        });
      });

    }
  }
}
