import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './theme/shared/header/header.component';
import { FooterComponent } from './theme/shared/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { MatDatepickerModule, MatNativeDateModule, MatTooltipModule, _MatTabHeaderBase } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { RequestsComponent } from './pages/requests/requests.component';
import { ErrorMessageComponent } from './theme/error-message/error-message.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { RequestDetailsComponent } from './pages/request-details/request-details.component';
import { IgxTimePickerModule } from 'igniteui-angular';
import { UsersComponent } from './pages/users/users.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DriversComponent } from './pages/drivers/drivers.component';
import { AddDriverComponent } from './pages/add-driver/add-driver.component';
import { WeightsComponent } from './pages/weights/weights.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AddNotificationsComponent } from './pages/add-notifications/add-notifications.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { MatSelectModule } from '@angular/material/select';
import { ToastrModule } from 'ngx-toastr';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EnvServiceProvider } from './helpers/environment/env.service.provider';
import { FeedBackComponent } from './pages/feed-back/feed-back.component';
import { FeedBackModelComponent } from './pages/feed-back/feed-back-model/feed-back-model.component';
import { LightboxModule } from 'ngx-lightbox';
import { UserIdleModule } from 'angular-user-idle';
import { CitiesComponent } from './pages/cities/cities.component';
import { CountriesComponent } from './pages/countries/countries.component';
import { AddCityComponent } from './pages/add-city/add-city.component';
import { CharitiesComponent } from './pages/charities/charities.component';
import { AddCharityComponent } from './pages/add-charity/add-charity.component';
import { DriversListComponent } from './pages/drivers-list/drivers-list.component';
import { AddCountryComponent } from './pages/add-country/add-country.component';
import { DonationTypesComponent } from './pages/donation-types/donation-types.component';
import { AddDonationTypeComponent } from './pages/add-donation-type/add-donation-type.component';
import { TrucksLogComponent } from './pages/trucks-log/trucks-log.component';
import { AddTruckLogComponent } from './pages/add-truck-log/add-truck-log.component';
import { DonationBoxesListComponent } from './pages/donation-boxes/donation-boxes-list/donation-boxes-list.component';
import { AddDonationBoxComponent } from './pages/donation-boxes/add-donation-box/add-donation-box.component';
import { DonationBoxDetailsComponent } from './pages/donation-boxes/donation-box-details/donation-box-details.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FillDonationBoxCheckReportComponent } from './pages/donation-boxes/fill-donation-box-check-report/fill-donation-box-check-report.component';
import { AppSettings } from "./appsettings";
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
import { FillDonationBoxMaintenanceReportComponent } from './pages/donation-boxes/fill-donation-box-maintenance-report/fill-donation-box-maintenance-report.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { AddDonationBoxWeightRecordComponent } from './pages/donation-boxes/donation-box-weight-log/add-donation-box-weight-record/add-donation-box-weight-record.component';
import { DonationBoxWeightListComponent } from './pages/donation-boxes/donation-box-weight-log/donation-box-weight-list/donation-box-weight-list.component';
import {AddDonationBoxFinancialSettlementComponent  } from './pages/donation-boxes/donation-box-weight-log/add-donation-box-financial-settlement/add-donation-box-financial-settlement.component';
import { DonationBoxCharityContractsListComponent } from './pages/donation-boxes/donation-box-charity-contracts-list/donation-box-charity-contracts-list.component';
import { DonationBoxFinancialSettlementsListComponent } from './pages/donation-boxes/donation-box-financial-settlements-list/donation-box-financial-settlements-list.component';
import { AddCharityContractComponent } from './pages/donation-boxes/add-charity-contract/add-charity-contract.component';
import { AddNewRequestComponent } from './pages/add-new-request/add-new-request.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#f6931d",
  pbColor:"#f6931d",
  bgsPosition: POSITION.centerCenter,
  bgsSize: 40,
  bgsType: SPINNER.ballSpinClockwise,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
  fgsSize: 0,
  hasProgressBar: true,
  overlayColor:"transparent"	 // progress bar thickness
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    RequestsComponent,
    ErrorMessageComponent,
    RequestDetailsComponent,
    UsersComponent,
    AddUserComponent,
    DriversComponent,
    AddDriverComponent,
    WeightsComponent,
    NotificationsComponent,
    AddNotificationsComponent,
    CustomersComponent,
    AddCustomerComponent,
    ChangePasswordComponent,
    FeedBackComponent,
    FeedBackModelComponent,
    CitiesComponent,
    CountriesComponent,
    AddCityComponent,
    AddCountryComponent,
    CharitiesComponent,
    AddCharityComponent,
    DriversListComponent,
    DonationTypesComponent,
    AddDonationTypeComponent,
    TrucksLogComponent,
    AddTruckLogComponent,
    DonationBoxesListComponent,
    AddDonationBoxComponent,
    DonationBoxDetailsComponent,
    FillDonationBoxCheckReportComponent,
    FillDonationBoxMaintenanceReportComponent,
    AddDonationBoxWeightRecordComponent,
    DonationBoxWeightListComponent,
    AddDonationBoxFinancialSettlementComponent,
    DonationBoxCharityContractsListComponent,
    DonationBoxFinancialSettlementsListComponent,
    AddCharityContractComponent,
    AddNewRequestComponent
  ],
  exports: [
    TranslateModule
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    IgxTimePickerModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    LightboxModule,
    UserIdleModule,
    MatTableExporterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgbModule,
    QRCodeModule,
    BootstrapModalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    })
  ],
  providers: [MatDatepickerModule,EnvServiceProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }
