import { Injectable } from '@angular/core';
import { BaseService } from '../common/base.service';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(public router: Router, public baseService: BaseService, route: ActivatedRoute) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    let expectedRole;
    if (this.baseService.getLocalStorageItem('userObj')) {
      if (route.routeConfig.data && route.routeConfig.data.length > 0) { expectedRole = this.findRoleName(route.routeConfig.data); }
      else { expectedRole = route.data.expectedRole; }
      if (expectedRole) {
        if (expectedRole == this.baseService.getLocalStorageItem('userObj').role) { return true; }
        else { this.getRoleBaseRoute(this.baseService.getLocalStorageItem('userObj').role); }
      }
      else { return true; }
    }
    else {
      this.getRoleBaseRoute('');

    }
  }
  findRoleName(roleNames: any) {
    roleNames.forEach(roleCode => {
      if (roleCode.expectedRole == this.baseService.getLocalStorageItem('userObj').role) { return roleCode.expectedRole; }
    });
  }
  getRoleBaseRoute(getRoleCode: any) {
    // alkhaireya.net versions
    if (window.location.hostname == 'alkhaireya.net') {
      // inventory role
      if (getRoleCode == "inventory") {
        this.router.navigateByUrl('/donation-box-weight-list');
      // accountant role  
      } else if (getRoleCode == "accountant") {
        this.router.navigateByUrl('/donation-box-financial-settlements-list');
      // donation box role  
      } else if (getRoleCode == "donationBox") {
        this.router.navigateByUrl('/donation-boxes-list');

      // admin and charity roles  
      } else if (getRoleCode == "admin" || getRoleCode == "charityUser") {
        this.router.navigateByUrl('/donation-boxes-list');
      }else {
        this.router.navigateByUrl('');
      }

      // aonk.net versions
    } else if (window.location.hostname == 'aonk.net') {

      if (getRoleCode == "admin" || getRoleCode == "charityUser") {
        this.router.navigateByUrl('/dashboard');
      } else if (getRoleCode == "user" || getRoleCode == "admin-country" || getRoleCode == "country-requests") {
        this.router.navigateByUrl('/requests');
      }else if (getRoleCode == "country-weight") {
        this.router.navigateByUrl('trucks-log');
      }else {
        this.router.navigateByUrl('');
      }
      // development environment
    } else if (window.location.hostname == 'localhost'){
      // inventory role
      if (getRoleCode == "inventory") {
        this.router.navigateByUrl('/donation-box-weight-list');
      // accountant role  
      } else if (getRoleCode == "accountant") {
        this.router.navigateByUrl('/donation-box-financial-settlements-list');
      // donation box role  
      } else if (getRoleCode == "donationBox") {
        this.router.navigateByUrl('/donation-boxes-list');

      // admin and charity roles  
      } else if (getRoleCode == "admin" || getRoleCode == "charityUser") {
        this.router.navigateByUrl('/donation-boxes-list');
      }else if (getRoleCode == "admin" || getRoleCode == "charityUser") {
        this.router.navigateByUrl('/dashboard');
      } else if (getRoleCode == "user" || getRoleCode == "admin-country" || getRoleCode == "country-requests") {
        this.router.navigateByUrl('/requests');
      }else if (getRoleCode == "country-weight") {
        this.router.navigateByUrl('trucks-log');
      }else {
        this.router.navigateByUrl('');
      }
    }
  }
}
