import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { matchingPasswords } from 'src/app/helpers/Validators/app-validators';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { BaseService } from 'src/app/helpers/common/base.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  response:any;
  constructor(public formBuilder: FormBuilder, 
    public validationMessages: ValidationMessagesService,
    public globalMethodsService: GlobalMethodsService,
    public httpHelper: HttpClientService,
    public baseService:BaseService,
     public toastrService: ToastrService) {  window.scroll(0,0);}

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      'confirmPassword': ['', Validators.compose([Validators.required])],
      'newPassword': ['', Validators.compose([Validators.required])],
      'Id':[this.baseService.getLocalStorageItem('userObj').id],
    }, { validator: matchingPasswords('newPassword', 'confirmPassword') });
  }
  changePasswordSubmit(){
    if(this.changePasswordForm.valid){
      //debugger
      this.globalMethodsService.loader.startBackground();
      let Data={
        Id:this.changePasswordForm.value.Id,
        password:this.changePasswordForm.value.newPassword
      };
      this.httpHelper.put('User/update',Data).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.toastrService.success("Password Changed");
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
    }
    else{
      this.validationMessages.validateAllFormFields(this.changePasswordForm); 
    }
  }

}
