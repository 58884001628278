import { Component, OnInit,Inject } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service'
import { TooltipPosition } from '@angular/material/typings';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { BaseService } from 'src/app/helpers/common/base.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  positionOptions: TooltipPosition[] = ['above']; //'after', 'before', 'above', 'below', 'left', 'right'
  position = new FormControl(this.positionOptions[0]);
  aonkMode: boolean = false;
  alkhaireyaMode: boolean = false;
  public now: Date = new Date();

  constructor(@Inject(DOCUMENT) private document: Document,
    public route: ActivatedRoute,
    public translate: TranslateService,
    public baseService: BaseService,
    public globalService: GlobalMethodsService) {
    setInterval(() => {
      this.now = new Date();
    }, 1);
    setTheme('bs4');
    if(window.location.hostname == "alkhaireya.net"){
      this.alkhaireyaMode = true;
    }else if(window.location.hostname == "aonk.net"){
      this.aonkMode = true;
    }else if(window.location.hostname == "localhost"){
      this.alkhaireyaMode = true;
    }
  }




  toggleNav: boolean = false;
  toggleNavClass: boolean = true;

  ngOnInit() {
  }

  switchLanguage(){
    let currentLang = this.baseService.getLocalStorageItem('lang');
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;

    if(currentLang == 'en'){
      this.baseService.createLocalStorageItem('lang','ar');
      this.translate.setDefaultLang('ar');
      htmlTag.dir = 'rtl';

    }else{
      this.baseService.createLocalStorageItem('lang','en');
      this.translate.setDefaultLang('en');
      htmlTag.dir = 'ltr';
    }

  }

  onMenuClick() {
    if(this.toggleNav){
      this.toggleNav=false;
    }
    else{
      this.toggleNav = true;
    }

    if(this.toggleNavClass == true){
      this.toggleNavClass=false;
    }
    else{
      this.toggleNavClass = true;
    }
  }


}
