import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { TooltipPosition } from '@angular/material';
import { FormControl } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';

export interface UserData {
  name: string;
  loginId: string;
  mobileNo: string;
  UserType:string;
  country:string;
  city: string;
  active: string;
}
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  response: any;
  users: any;
  countryAllSelected: boolean = true;
  countryid:number=0;
  responseDelete: any;
  displayedColumns: string[] = ['userName', 'mobile','country', 'city','userType', 'isActive'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(public globalMethodsService: GlobalMethodsService,
    private route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public baseService: BaseService) {
      window.scroll(0,0);
    //debugger
    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      this.users = this.response.result;
    }
    else {
      this.users = [];
    }
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.users);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onChange(userId, ev) {
    //debugger
    let userActive = {
      "Id": userId,
      "isActive": ev.checked,
    }

    this.globalMethodsService.loader.startBackground();
    this.httpHelper.put('User/update', userActive).then(result => {
      this.globalMethodsService.loader.stopBackground();
      this.responseDelete = result;
      if (this.responseDelete.statusCode == 200) {
        this.globalMethodsService.loader.stopBackground();
      }
      else {
        this.globalMethodsService.loader.stopBackground();
        this.globalMethodsService.showErrorToast();
      }
    });
  }
  createString(arr, key) {
    return arr.map(function (obj) {
      return "" + obj[key] + "";
    }).join(', ');
  }
  countrySelect(ev){
    this.countryid = +ev.currentTarget.value;
    this.globalMethodsService.loader.startBackground();
    this.httpHelper.getWithAuthToken('User?countryId='+this.countryid).then(result => {
      this.response = result;
      
      if (this.response.result.length > 0) {
        this.users = this.response.result;
        //console.log(this.users);
        this.dataSource = new MatTableDataSource(this.users);
        this.globalMethodsService.loader.stopAll();
        return this.users;
      }
      else { this.users = [];
        this.dataSource=new MatTableDataSource(this.users) ;
        this.globalMethodsService.loader.stopAll();
        return this.users;}
        
     // this.dwsTransactions = this.byDriver;
    
    })
   
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
