import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from 'src/app/helpers/animations/fade.animation';
import { RouterOutlet, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { AppComponent } from 'src/app/app.component';
import { dateLessThan } from 'src/app/helpers/Validators/app-validators';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { BaseService } from 'src/app/helpers/common/base.service';
import { Console } from 'console';
import { ComponentPortal } from '@angular/cdk/portal';


// Emirates Wise Status
export interface ewsTransaction {
  city: string;
  created: number;
  inProgress: number;
  completed: number;
  total: number;
  weight: number;
  other: number;
  duplicates: number;
  noAnswers: number
}

// Drivers Wise Status
export interface dwsTransaction {
  id: number;
  name: string;
  inProgress: number;
  completed: number;
  total: number;
  weight: number;
  loading: any; 
}

/**
* @title Table with a sticky footer
*/

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fadeAnimation]
})
export class HomeComponent implements OnInit {
  response: any;
  byState: any;
  byDriver: any;
  filterByDate: FormGroup;
  startDate = new Date();
  endDate = new Date();
  countryId = 0;
  charityId = 0;
  gridHeading = "الاحصائيات حسب الدولة";
  ColumnHeading = "الدولة";
  // countries:any;
  // Emirates Wise Status
  displayedColumns: string[] = ['city', 'new', 'inProgress', 'completed', 'other', 'total'];
  charityUserColumns: string[] = ['city', 'new', 'inProgress', 'completed', 'total', 'weight'];
  ewsTransactions: ewsTransaction[] = [];
  /** Gets the total weightKg of all ewsTransactions. */
  getTotalKg() {
    return this.ewsTransactions.map(t => t.weight).reduce((acc, value) => acc + value, 0);

  }
  getTotalKgdws() {
    return this.dwsTransactions.map(t => t.weight).reduce((acc, value) => acc + value, 0);

  }

  /** Gets the total new of all ewsTransactions. */
  getNew() {
    return this.ewsTransactions.map(t => t.created).reduce((acc, value) => acc + value, 0);
  }
  /** Gets the total inProgress of all ewsTransactions. */
  getInProgress() {
    return this.ewsTransactions.map(t => t.inProgress).reduce((acc, value) => acc + value, 0);
  }
  getInProgressdws() {
    return this.dwsTransactions.map(t => t.inProgress).reduce((acc, value) => acc + value, 0);
  }
  /** Gets the total completed of all ewsTransactions. */
  getCompleted() {
    return this.ewsTransactions.map(t => t.completed).reduce((acc, value) => acc + value, 0);
  }
  getOther() {
    return this.ewsTransactions.map(t => t.other).reduce((acc, value) => acc + value, 0);
  }
  getTotalWeight() {
    return this.ewsTransactions.map(t => t.weight).reduce((acc, value) => acc + value, 0);
  }
  getCompleteddws() {
    return this.dwsTransactions.map(t => t.completed).reduce((acc, value) => acc + value, 0);
  }

  /** Gets the total Total of all ewsTransactions. */
  getTotal() {
    return this.ewsTransactions.map(t => t.total).reduce((acc, value) => acc + value, 0);
  }
  getTotaldws() {
    return this.dwsTransactions.map(t => t.total).reduce((acc, value) => acc + value, 0);
  }

  // Emirates Wise Status
  displayedColumns1: string[] = ['name', 'inProgress', 'completed', 'total', 'weight'];
  dwsTransactions: dwsTransaction[] = [];
  constructor(public globalMethodsService: GlobalMethodsService,
    public baseService: BaseService,
    private route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder, public appComp: AppComponent, public validationMessages: ValidationMessagesService, ) {
    // this.countries=this.globalMethodsService.countries;
    this.countryId = 0;
    window.scroll(0, 0);
    if (this.baseService.getLocalStorageItem('userObj').role == 'charityUser') {
      this.gridHeading = 'الاحصائيات حسب المدينة/الولاية';
      this.charityId = this.baseService.getLocalStorageItem('userObj').charityId;
    }
    this.startDate = this.globalMethodsService.setFromDate(this.startDate, -3);
    this.appComp.showHeader = true;
    this.response = this.route.snapshot.data.data;
    if (this.response.length > 0) {
      this.byState = this.response;
    }
    else {
      this.byState = [];
    }
    
    this.ewsTransactions = this.byState;
    this.ewsTransactions.forEach(otherData => {
      otherData.other = otherData.duplicates + otherData.noAnswers;
    })
  }

  ngOnInit() {

    let endDate = this.globalMethodsService.setDate(this.endDate);
    let data = {
      "startDate": this.startDate,
      "endDate": endDate,
      "countryId": this.countryId
    }
    this.getByDrivers(data);
    if (this.baseService.getLocalStorageItem('userObj').role == 'admin' && this.response.statusCode == 200) {
      let data = {
        "startDate": this.startDate,
        "endDate": endDate,
        "countryId": this.countryId
      }
      this.getByDrivers(data);
    
    }else{
      this.globalMethodsService.loader.stopAll();
    }
    this.filterByDate = this.formBuilder.group({
      'startDate': [new Date(this.startDate), Validators.compose([Validators.required])],
      'endDate': [this.endDate, Validators.compose([Validators.required])],
      'countryId': [this.countryId],
      'charityId': [this.charityId]
    }, { validator: dateLessThan('startDate', 'endDate') });
  }
  ngAfterViewInit() {
    this.appComp.showHeader = true;
  }
  getByDrivers(data: any) {
    let endDate = this.globalMethodsService.setDate(this.endDate);
    // let data = {
    //   "startDate": this.startDate,
    //   "endDate": endDate,
    //   "countryId":this.countryId
    // }

    this.httpHelper.postWithAuthToken('Common/byDriver', data).then(result => {
      this.response = result;
      if (this.response.length > 0) {
        this.byDriver = this.response;
      }
      else { this.byDriver = []; }
      this.dwsTransactions = this.byDriver;
      this.globalMethodsService.loader.stopAll();
    });
  }
  filterByDateSubmit() {
    //debugger
    if (this.filterByDate.value.startDate != null && this.filterByDate.value.endDate != null && this.filterByDate.value.countryId != null) {
      if (this.filterByDate.valid) {

        if (Number(this.filterByDate.value.countryId) > 0) {
          this.gridHeading = "الاحصائيات حسب المدينة/الولاية";
          this.ColumnHeading = "المدينة";
        }
        else {
          this.gridHeading = "الاحصائيات حسب الدولة";
          this.ColumnHeading = "الدولة";
        }

        this.globalMethodsService.loader.startBackground();
        this.ewsTransactions = [];
        this.dwsTransactions = [];
        
        let startDate = this.globalMethodsService.setDate(this.filterByDate.value.startDate);
        let endDate = this.globalMethodsService.setDate(this.filterByDate.value.endDate);
        let countryId = Number(this.filterByDate.value.countryId);
        //debugger
        let data = {
          "startDate": startDate,
          "endDate": endDate,
          "countryId": countryId,
          "charityId": this.charityId
        }
        if (this.baseService.getLocalStorageItem('userObj').role == 'charityUser') {
          delete data.countryId
        }
        else if (this.baseService.getLocalStorageItem('userObj').role == 'admin') {
          delete data.charityId;
        }
        this.httpHelper.postWithAuthToken('Common/byState', data).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.length > 0) {
            this.byState = this.response;
          }
          else { this.byState = []; }
          this.ewsTransactions = this.byState;
          this.ewsTransactions.forEach(otherData => {
            otherData.other = otherData.duplicates + otherData.noAnswers;
          })
        });
        if (this.baseService.getLocalStorageItem('userObj').role == 'admin') {
          this.getByDrivers(data);
        }
      }
      else {
        this.validationMessages.validateAllFormFields(this.filterByDate);
      }
    }
  }
}
