import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import { nameArValidator } from 'src/app/helpers/Validators/app-validators';
import { Observable } from 'rxjs';
import { map, isEmpty } from 'rxjs/operators';


@Component({
  selector: 'app-add-country',
  templateUrl: './add-truck-log.component.html',
  styleUrls: ['./add-truck-log.component.scss']
})
export class AddTruckLogComponent implements OnInit {
  response: any;
  countryDetail: any;
  pageHeading: any = 'Add New Truck Log';
  countryId: number;
  addTruckLogForm: FormGroup;
  driversResponse: Observable<any>;
  entryDate;
  map;
  truckLogDetail:any;
  
  constructor(public httpService: HttpClientService,
    public baseService: BaseService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public formBuilder: FormBuilder, 
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    window.scroll(0, 0);
    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    this.getDrivers();
 
      if (this.route.url['value'][0].path == 'edit-truck-log') {
        this.pageHeading = 'Edit Truck Log';
        let logId = this.route.url['value'][1].path;
        this.getDetails();
        
      }
      
    
  }
  ngOnInit() {
    this.addTruckLogForm= this.formBuilder.group({
      'entryDate': ['', Validators.compose([Validators.required])],
      'driverId': ['', Validators.compose([Validators.required])],
      'totalWeight': ['', Validators.compose([Validators.required])]
    });


  }

  getDetails(){

    this.httpHelper.getWithAuthToken('TruckWeight/' + this.route.url['value'][1].path).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.statusCode == 200) {
        this.truckLogDetail = this.response.result;
        this.setFormData();
      }

    });
  }

  setFormData() {

    this.addTruckLogForm.controls['entryDate'].setValue(this.truckLogDetail.entryDate);
    this.addTruckLogForm.controls['driverId'].setValue(this.truckLogDetail.driverId);
    this.addTruckLogForm.controls['totalWeight'].setValue(this.truckLogDetail.totalWeight);

  }
  getDrivers() {

    this.driversResponse = this.httpService.getWithAuthToken2('Drivers?countryId=1').pipe(map(resp => resp["result"]));
  }

  addCountrySubmit() {
    if (this.addTruckLogForm.valid) {
      this.globalMethodsService.loader.startBackground();

      this.addTruckLogForm.value.entryDate = this.baseService.setToDate(this.addTruckLogForm.value.entryDate);
      this.addTruckLogForm.value.driverId = parseInt(this.addTruckLogForm.value.driverId);
      this.addTruckLogForm.value.totalWeight = parseFloat(this.addTruckLogForm.value.totalWeight);

      if (this.route.url['value'][0].path == 'edit-truck-log') {

        this.addTruckLogForm.value.Id = parseInt(this.route.url['value'][1].path);

        this.httpHelper.put('TruckWeight/edit', this.addTruckLogForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/trucks-log');
          }
          else {
            this.globalMethodsService.showErrorToast();
          }
        });
      }else{
        this.httpHelper.put('TruckWeight/add', this.addTruckLogForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/trucks-log');
          }
          else if (this.response.statusCode == 409) {
            this.toastrService.error("Country Code Already exist")
          }
          else {
            this.globalMethodsService.showErrorToast();
          }
        });
      }

    }
  }

}
