import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import { EnvService } from 'src/app/helpers/environment/env.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { EBADF } from 'constants';

@Component({
  selector: 'app-add-donation-box-financial-settlementd',
  templateUrl: './add-donation-box-financial-settlement.component.html',
  styleUrls: ['./add-donation-box-financial-settlement.component.scss']
})
export class AddDonationBoxFinancialSettlementComponent implements OnInit {
  response: any;
  charities: any;
  formControl: FormGroup;
  dateFrom: any;
  dateTo: any;
  fileName: any;
  baseURL: string;
  url: string;
  progress: string;
  selectedCharityId:any;
  selectedFromDate: any = "";
  selectedToDate: any = "";
  ratePerTon: any = "";
  totalWeight: any = "";
  fullAmount: any = "";

  constructor(private env: EnvService,
    public formBuilder: FormBuilder,
    public baseService: BaseService,
    public globalService: GlobalMethodsService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public route: ActivatedRoute,
    private http: HttpClient,
    public toastrService: ToastrService) {
    window.scroll(0, 0);

    this.baseURL = this.env.apiUrl;
  }
  ngOnInit() {
    // get charities to populate the select menu
    this.getCharities();

    // prepare the form object and its validation rules
    this.formControl = this.formBuilder.group({
      'dateFrom': ['', Validators.compose([Validators.required])],
      'dateTo': ['', Validators.compose([Validators.required])],
      'file': ['', Validators.compose([Validators.required])],
      'amount': ['', Validators.compose([Validators.required])],
      'amountPerTon': ['', Validators.compose([Validators.required])],
      'totalWeight': ['', Validators.compose([Validators.required])],
      'charityId': ['', Validators.compose([Validators.required])]
    });
    
    this.globalMethodsService.loader.stopAll();

  }

  onCharitySelected(ev){
    console.log(ev)
    this.selectedCharityId = ev.value;
    this.updateAmountPerTon();
    this.updateTotalWeight();

  }

  
  onFromDateSelected(ev){
    this.selectedFromDate =this.globalMethodsService.setDate( this.formControl.controls['dateFrom'].value);
    this.updateTotalWeight();

  }

  onToDateSelected(ev){
    this.selectedToDate = this.globalMethodsService.setDate(this.formControl.controls['dateTo'].value);
    this.updateTotalWeight()
  

    
  }

  updateAmountPerTon(){
    let rate = null;

      this.httpHelper.getWithAuthToken('DonationBoxes/getRatePerTon?charityId=' + this.selectedCharityId).then(result => {
        this.response = result;
        if (this.response.result.length > 0) {
          rate = this.response.result;
          this.ratePerTon = rate[0];
          if(this.totalWeight != "" && this.ratePerTon != ""){
            this.fullAmount = this.totalWeight * parseFloat(this.ratePerTon);
          }
        }else{
          this.ratePerTon = "";
        }
        
      })
    
  }

  updateTotalWeight(){
    let rate = null;
    if(this.selectedToDate != "" && this.selectedFromDate != "" && this.selectedCharityId != ""){
      this.httpHelper.getWithAuthToken('DonationBoxes/getTotalWeights?charityId=' + this.selectedCharityId +"&dateFrom=" + this.selectedFromDate+"&dateTo=" + this.selectedToDate).then(result => {
        this.response = result;
          rate = this.response.result;
          this.totalWeight = rate/1000;
          this.fullAmount = this.totalWeight * parseFloat(this.ratePerTon);
        
      })
    }
  }


  submitForm() {
    if (this.formControl.valid) {
      let data = {
        charityId: this.selectedCharityId,
        fromDate: this.selectedFromDate,
        toDate: this.selectedToDate,
        weightInTons: this.totalWeight,
        ratePerTon: this.ratePerTon,
        amountPaid: this.fullAmount,
        attachmentId: this.formControl.controls['file'].value,
        createdBy: this.baseService.getLocalStorageItem('userObj').id

      }

      this.globalMethodsService.loader.startBackground();

      this.httpHelper.put('DonationBoxes/addDonationBoxFinancialSettlement', data).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.router.navigateByUrl('/donation-box-financial-settlements-list');
        }
        else if (this.response.statusCode == 409) {
          this.toastrService.error("Box exist already")
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
      

    }
  }

  getCharities() {
    this.globalMethodsService.loader.startBackground();
    let data = {

    }
    this.httpHelper.put('Common/getDonationBoxesCharities', data).then(result => {
      this.response = result;
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
      }
      else { this.charities = []; }
    })
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      let fileToUpload = <File>event.target.files[0];
      
      /**if (fileToUpload.type.split('/')[0].toLowerCase() != 'image') {
        this.formControl.controls.file.setErrors({ 'invalidType': true });
      }**/
     
        this.fileName=fileToUpload.name;
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        this.http.put(this.baseURL + 'Common/uploadFile', formData, { reportProgress: true, observe: 'events' }).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = String(Math.round(100 * event.loaded / event.total)) + '%';
          else if (event.type === HttpEventType.Response) {
            this.response = event.body;
            if (this.response.statusCode == 200) {
              //debugger
              this.url = this.response.url;
              this.progress = '';
              this.formControl.controls['file'].setValue(this.response.attachmentId);
            }
            else {
              this.globalMethodsService.showErrorToast();
              this.formControl.controls['file'].setValue('');
            }
          }
        });
      
    }
  }
}
