import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { MatTableDataSource, TooltipPosition } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
declare var google: any;

export interface UserData {
  id: string,
  name: string,
  'city.nameAr': string,
  'chairty.nameAr': string
}

@Component({
  selector: 'app-donation-box-charity-contracts-list',
  templateUrl: './donation-box-charity-contracts-list.component.html',
  styleUrls: ['./donation-box-charity-contracts-list.component.scss']
})
export class DonationBoxCharityContractsListComponent implements OnInit {
  response: any;
  currentDate: Date;
  donationBoxes: any;
  charities: any;
  selectedCharityId: Number;
  displayedColumns: string[] = ['id', 'charity.nameAr','ratePerTon', 'validFromDate', 'validToDate', 'attachment','valid'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  markers = [];
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(public globalMethodsService: GlobalMethodsService,
    globalService: GlobalMethodsService,
    private route: ActivatedRoute,
    public httpHelper: HttpClientService) {
    window.scroll(0, 0);

    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {

      this.donationBoxes = this.response.result;
      this.dataSource = new MatTableDataSource(this.donationBoxes);

    }

    this.currentDate = new Date();
  }

  ngOnInit() {
    // get list of active charities in Oman
    this.setSortingDataAccessorForNestedObjects();
    this.globalMethodsService.loader.stopAll();
  }

  ngAfterViewInit(): void {
  }

  /**
   * Allow nested objects to be accessed for sorting in the table.
   */
  setSortingDataAccessorForNestedObjects() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      // if the property includes (.), means it's nested field in JSON object
      if (property.includes('.')) {
        const accessor = property.split('.');
        let value: any = item;
        accessor.forEach((a) => {
          value = value[a];
        });

        // return the value of the nested object
        return value;
      }

      // if it is not nested field, then return the value as-is
      return item[property];
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }




  /**
   * Load dontation boxes data in the table.
   */
  loadDonationBoxesInTable(){
    // if there is data, load them immediately
    if (this.response.result.length > 0) {
      this.donationBoxes = this.response.result;
      this.dataSource = new MatTableDataSource(this.donationBoxes);
      this.setSortingDataAccessorForNestedObjects();

      
    } else {
      this.donationBoxes = [];
      this.dataSource = new MatTableDataSource(this.donationBoxes);
    }

  }


}
