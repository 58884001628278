import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import LocationPicker from "location-picker";
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EnvService } from 'src/app/helpers/environment/env.service';

@Component({
  selector: 'app-fill-donation-box-maintenance-report',
  templateUrl: './fill-donation-box-maintenance-report.component.html',
  styleUrls: ['./fill-donation-box-maintenance-report.component.scss']
})
export class FillDonationBoxMaintenanceReportComponent implements OnInit {
  response: any;
  donationBoxDetail: any;
  pageHeading: any = 'ملء التقرير الدوري لصيانة صندوق التبرع';
  fillDonationBoxCheckReportForm: FormGroup;
  donationBoxDetails: any;
  baseURL:any;
  lp: LocationPicker;
  public progress: string;
  public progress1: string;
  public progress2: string;
  public progress3: string;
  url = '';
  firstAttachment = '';
  secondAttachment = '';
  thirdtAttachment = '';
  fileName: string = 'Choose File';
  fileName1: string = 'التقط صورة من الداخل';
  fileName2: string = 'التقط صورة من الخارج';
  fileName3: string = 'التقط صورة من الأعلى';
  reporterLocation: string;

  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    private http: HttpClient,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    private env: EnvService,
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    this.baseURL = this.env.apiUrl;
    window.scroll(0, 0);
    }
  ngOnInit() {
    this.response = this.route.snapshot.data.data;
    this.donationBoxDetails = this.response.result;

    let location = this.donationBoxDetails[0].location.split(",");
    this.lp = new LocationPicker('map', {
      lat: location[0], lng: location[1]
    });

  

    this.fillDonationBoxCheckReportForm = this.formBuilder.group({
      'donationBoxId': ['', Validators.compose([Validators.required])],
      'details': ['', Validators.compose([Validators.required])],
      'reporterId': ['', Validators.compose([Validators.required])],
      'reporterLocation': ['', Validators.compose([Validators.required])],
      'createdBy':['']
    });

    this.fillDonationBoxCheckReportForm.controls['reporterId'].setValue (1);
    this.fillDonationBoxCheckReportForm.controls['donationBoxId'].setValue (this.donationBoxDetails[0].id);
    this.fillDonationBoxCheckReportForm.controls['reporterLocation'].setValue (this.donationBoxDetails[0].location);

    // Get current location of the reporter
    navigator.geolocation.getCurrentPosition((position) => { 
      this.reporterLocation = position.coords.latitude.toString() + "," + position.coords.longitude.toString(); 
 
    });

    this.globalMethodsService.loader.stopAll();

  }
  setFormData() {
    this.fillDonationBoxCheckReportForm.controls['details'].setValue(this.donationBoxDetail[0].notes);
    this.fillDonationBoxCheckReportForm.controls['reporterId'].setValue(this.donationBoxDetail[0].city.id);
    this.fillDonationBoxCheckReportForm.controls['donationBoxId'].setValue(this.donationBoxDetail[0].donationBoxId);
    this.fillDonationBoxCheckReportForm.controls['reporterLocation'].setValue(this.reporterLocation);

    this.globalMethodsService.loader.stopAll();
  }

  fillDonationBoxCheckReportFormSubmit() {

    if (this.fillDonationBoxCheckReportForm.valid) {
      this.globalMethodsService.loader.startBackground();
      this.fillDonationBoxCheckReportForm.controls['createdBy'].setValue(this.baseService.getLocalStorageItem('userObj').id);

        this.httpHelper.put('DonationBoxes/addDonationBoxMaintenanceReport', this.fillDonationBoxCheckReportForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/donation-boxes-list');
          }
          else if (this.response.statusCode == 409) {
            this.toastrService.error("Box exist already")
          }
          else {
            this.globalMethodsService.showErrorToast();
          }
        });
      

    }
  }


}
