import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import LocationPicker from "location-picker";

@Component({
  selector: 'app-add-donation-box',
  templateUrl: './add-donation-box.component.html',
  styleUrls: ['./add-donation-box.component.scss']
})
export class AddDonationBoxComponent implements OnInit {
  response: any;
  donationBoxDetail: any;
  pageHeading: any = 'اضافة صندوق تبرع جديد';
  countryId: number;
  charities: any;
  cities: any;
  addDonationBoxForm: FormGroup;
  lp: LocationPicker;
  reporterlocation: any;
  lat: any = 23.597623341357075;
  lng: any = 58.24938350427372;

  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    public globalService: GlobalMethodsService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    this.addDonationBoxForm = this.formBuilder.group({
      'name': ['', Validators.compose([Validators.required])],
      'cityId': ['', Validators.compose([Validators.required])],
      'charityId': ['', Validators.compose([Validators.required])],
      'location': ['', Validators.compose([Validators.required])],
      'createdBy': [''],
      'id': ['']
    });
    this.getCharities();
    this.getCities();
    this.lp = new LocationPicker('map', {
      lat: this.lat, lng: this.lng});



    if (this.route.url['value'][0].path == 'edit-donation-box') {
      this.response = this.route.snapshot.data.data;
      this.pageHeading = 'تعديل معلومات صندوق التبرع';
      if (this.response.statusCode == 200) {
        this.donationBoxDetail = this.response.result;
        this.setFormData();

      }
      else {


        this.globalMethodsService.loader.stopAll();
      }
    }else{
              // Get current location of the reporter
              navigator.geolocation.getCurrentPosition((position) => {
                this.reporterlocation = position.coords.latitude.toString() + "," + position.coords.longitude.toString();
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                this.lp = new LocationPicker('map', {
                  lat: this.lat, lng: this.lng
                });
              });
    }
  }
  setFormData() {
    this.countryId = this.donationBoxDetail[0].headId;
    this.addDonationBoxForm.controls['name'].setValue(this.donationBoxDetail[0].name);
    this.addDonationBoxForm.controls['location'].setValue(this.donationBoxDetail[0].location);
    this.addDonationBoxForm.controls['cityId'].setValue(this.donationBoxDetail[0].city.id);
    this.addDonationBoxForm.controls['charityId'].setValue(this.donationBoxDetail[0].charity.id);
    this.addDonationBoxForm.controls['id'].setValue(this.donationBoxDetail[0].id);
    this.addDonationBoxForm.controls['createdBy'].setValue(this.donationBoxDetail[0].createdBy);

    let location = this.addDonationBoxForm.value.location.split(",");
    this.lp = new LocationPicker('map', {
      lat: location[0], lng: location[1]
    });

    this.globalMethodsService.loader.stopAll();
  }

  addDonationBoxSubmit() {
    this.addDonationBoxForm.controls['createdBy'].setValue(this.baseService.getLocalStorageItem('userObj').id);

    this.addDonationBoxForm.controls['location'].setValue(this.lp.getMarkerPosition().lat + "," + this.lp.getMarkerPosition().lng);
    if (this.addDonationBoxForm.valid) {
      this.globalMethodsService.loader.startBackground();

      if (this.route.url['value'][0].path == 'edit-donation-box') {
        let updateData = {
          id: this.addDonationBoxForm.value.id,
          name: this.addDonationBoxForm.value.name,
          location: this.addDonationBoxForm.value.location,
          cityId: this.addDonationBoxForm.value.cityId,
          charityId: this.addDonationBoxForm.value.charityId,

        }
        this.httpHelper.put('DonationBoxes/updateDonationBox', updateData).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/donation-boxes-list');
          }
          else {
            this.globalMethodsService.showErrorToast();
          }
        });
      } else {
        this.httpHelper.put('DonationBoxes/addDonationBox', this.addDonationBoxForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/donation-boxes-list');
          }
          else if (this.response.statusCode == 409) {
            this.toastrService.error("Box exist already")
          }
          else {
            this.globalMethodsService.showErrorToast();
          }
        });
      }

    }
  }

  getCharities() {
    this.globalMethodsService.loader.startBackground();
    let data = {

    }
    this.httpHelper.put('Common/getDonationBoxesCharities', data).then(result => {
      this.response = result;
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
      }
      else { this.charities = []; }
    })
  }

  getCities() {
    this.httpHelper.getWithAuthToken('Common/getCities').then(result => {
      this.response = result;
      if (this.response.result.length > 0) {
        this.cities = this.response.result;
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }
      else {
        this.cities = [];
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }
    })
  }
}
