import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationMessagesService {

  constructor(public formBuilder: FormBuilder) { }
  validation_messages = {
    'email': [
      { type: 'required', message: 'Please enter email', messageAr: 'يرجى ادخال بريدك الاكتروني المسجل' },
      { type: 'invalidEmail', message: 'Please enter a valid email address (example: abcd@xyz.com)', messageAr: "(مثال: abcd@xyz.com) يرجى ادخال بريد الكتروني فعال " },
    ],
    'password': [
      { type: 'required', message: 'Please enter your password', messageAr: 'يرجى ادخال كلمة المرور لتسجيل الدخول' },
    ],
    'newPassword': [
      { type: 'required', message: 'Please set your new password', messageAr: 'يرجى ادخال كلمة المرور الجديدة' },
      { type: 'maxlength', message: 'Enter password with min 6 chars or max 30 chars', messageAr: 'كلمة المرور يجب أن تشمل 5 أحرف ورقم واحد على الأقل' },
      { type: 'invalidPassword', message: 'Password must contain 5 letters and at least 1 number', messageAr: 'كلمة المرور يجب أن تشمل 5 أحرف ورقم واحد على الأقل' },
    ],
    'confirmPassword': [
      { type: 'required', message: 'Please confirm your password', messageAr: 'يرجى تأكيد كلمة المرور' },
      { type: 'mismatchedPasswords', message: 'Your password doesnt match. Please try again', messageAr: 'كلمة المرور غير متطابقة، حاول مرة أخرى' },
    ],
    //Add user
    'fullName': [
      { type: 'required', message: 'Please enter Full Name ', messageAr: 'يرجى ادخال الاسم باللغة الانجليزية' },
      { type: 'maxlength', message: 'Name cannot exceed 50 characters', messageAr: 'الاسم لا يتجاوز 50 حرفا' },
      { type: 'nameValidator', message: 'Please enter a valid name. (can only contain letters and space)', messageAr: ' (يحتوي على حروف ومسافات فقط(يرجى ادخال اسم صحيح' },
    ],
    'city': [
      { type: 'required', message: 'Please Select City', messageAr: 'يرجى ادخال الاسم باللغة العربية' },
    ],
    'cityId': [
      { type: 'required', message: 'Please Select City', messageAr: 'يرجى ادخال الاسم باللغة العربية' },
    ],
    'headId': [
      { type: 'required', message: 'Please Select Country', messageAr: 'يرجى ادخال الاسم باللغة العربية' }
    ],
    'country': [
      { type: 'required', message: 'Please Select Country', messageAr: 'يرجى ادخال الاسم باللغة العربية' }
    ],
    'weight': [
      { type: 'required', message: 'Please Enter Weight', messageAr: 'يرجى ادخال الوزن' },
      { type: 'min', message: 'Please Enter Weight greater than 0', messageAr: 'الوزن يجب أن يكون أكبر من 0' }
    ],
    'nameAr': [
      { type: 'required', message: 'Please enter name in Arabic', messageAr: 'يرجى ادخال الاسم باللغة العربية' },
      { type: 'nameArValidator', message: 'Please enter a valid name. (can only contain letters and space)', messageAr: ' (يحتوي على حروف ومسافات فقط(يرجى ادخال اسم صحيح' },
    ],
    'nameEn': [
      { type: 'required', message: 'Please enter name in English', messageAr: 'يرجى ادخال الاسم باللغة العربية' },
    ],
    'countryId': [
      { type: 'required', message: 'Please Select Country', messageAr: 'يرجى ادخال الاسم باللغة العربية' }
    ],
    'fullNameAr': [
      { type: 'required', message: 'Please enter name in Arabic', messageAr: 'يرجى ادخال الاسم باللغة العربية' },
      { type: 'maxlength', message: 'Name cannot exceed 50 characters', messageAr: 'الاسم لا يتجاوز 50 حرفا' },
      { type: 'nameArValidator', message: 'Please enter a valid name. (can only contain letters and space)', messageAr: ' (يحتوي على حروف ومسافات فقط(يرجى ادخال اسم صحيح' },
    ],
    'mobile': [
      { type: 'required', message: 'Please enter a mobile number ', messageAr: 'يرجى ادخال رقم الهاتف' },
      { type: 'phoneNumberValidator', message: 'Please enter a valid mobile number (00968/00973/00974/00965xxxxxxxx)', messageAr: '(00968/ 00973/ 00974xxxxxxxx)يرجى ادخال رقم هاتف صحيح' },
    ],
    'state': [
      { type: 'checkboxSelectionError', message: 'Please select a state', messageAr: 'يرجى اختيار دور' },
    ],
    'startDate': [
      { type: 'required', message: 'Please select `from Date`', messageAr: 'من فضلك اختر " من تاريخ"' },
    ],
    'endDate': [
      { type: 'required', message: 'Please select `to date`', messageAr: 'من فضلك اختر " الى تاريخ"' },
      { type: 'dateLessThan', message: '`from Date` should be less than `to Date`', messageAr: 'ـ"من تاريخ" يجب أن يكون أقل من " الى تاريخ"' }
    ],
    'userName': [
      { type: 'required', message: 'Please enter username', messageAr: 'يرجى ادخال اسم المستخدم' },
    ],
    'name': [
      { type: 'required', messageAr: 'يرجى ادخال اسم السائق' },
    ],
    'vehicleNo': [
      { type: 'required', messageAr: 'يرجى ادخال رقم المركبة' },
    ],
    'time': [
      { type: 'required', messageAr: 'يرجى ادخال وقت الشحن' },
    ],
    'date': [
      { type: 'required', messageAr: 'يرجى اختيار تاريخ الشحنe' },
      { type: 'timeLessThanValidator', messageAr: 'Please enter Valid Pickup Time' },
    ],
    'En': [
      { type: 'required', messageAr: 'Please enter Notification in English' },
    ],
    'Ar': [
      { type: 'required', messageAr: 'Please enter Notification in Arabic' },
      { type: 'nameArValidator', messageAr: 'Please enter a valid arabic Notification text. (can only contain letters and space)' }
    ],
    'status': [
      { type: 'required', messageAr: 'يرجى اختيار الحالة' },
    ],
    'remarks': [
      { type: 'required', messageAr: 'يرجى ادخال الملاحظات' },
    ],
    'driverId': [
      { type: 'required', message: 'Please Select Driver',messageAr: 'يرجى اختيار السائق' },
    ],
    'descriptionAr': [
      { type: 'nameArValidator', messageAr: 'Please enter a valid arabic Description text. (can only contain letters and space)' },
      { type: 'maxlength', messageAr: 'Description should not be greater than 4000 characters' },
    ],
    'descriptionEn': [
      { type: 'maxlength', messageAr: 'Description should not be greater than 4000 characters' },
    ],
    'website': [
      { type: 'pattern', messageAr: 'please Enter Correct Url' },
    ],
    'charityLogo': [
      { type: 'invalidType', messageAr: 'Please Select valid image file' }
    ],
    'charityId': [
      { type: 'required', messageAr: 'قم باختيار الجمعية/الجهة' }
    ],
    'countryCode': [
      { type: 'required', message: 'Please enter country code', messageAr: 'يرجى ادخال رمز الدولة' }
    ]
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
