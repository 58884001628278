import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import { nameArValidator } from 'src/app/helpers/Validators/app-validators';

@Component({
  selector: 'app-add-donation-type',
  templateUrl: './add-donation-type.component.html',
  styleUrls: ['./add-donation-type.component.scss']
})
export class AddDonationTypeComponent implements OnInit {
  response: any;
  donationTypeDeatail: any;
  pageHeading: any = 'Add New Donation Type';
  addDonationTypeForm: FormGroup;

  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    this.addDonationTypeForm = this.formBuilder.group({
      'nameEn': ['', Validators.compose([Validators.required])],
      'nameAr': ['', Validators.compose([Validators.required, nameArValidator])],
      'id': ['']
    });
    if (this.route.url['value'][0].path == 'edit-donation-type') {
      this.response = this.route.snapshot.data.data;
      this.pageHeading = 'Edit Donation Type';
      if (this.response.statusCode == 200) {
        this.donationTypeDeatail = this.response.result;
        this.setFormData();
      }
      else {
        this.globalMethodsService.loader.stopAll();
      }
    }
  }
  setFormData() {
    this.addDonationTypeForm.controls['nameEn'].setValue(this.donationTypeDeatail.nameEn);
    this.addDonationTypeForm.controls['nameAr'].setValue(this.donationTypeDeatail.nameAr);
    this.addDonationTypeForm.controls['id'].setValue(this.donationTypeDeatail.id);
    this.globalMethodsService.loader.stopAll();
  }
  addDonationTypeSubmit() {
    if (this.addDonationTypeForm.valid) {
      this.globalMethodsService.loader.startBackground();

      if (this.addDonationTypeForm.value.id == "") {
        this.addDonationTypeForm.value.id = 0;
      }
      else {
        this.addDonationTypeForm.value.id = +this.addDonationTypeForm.value.id
      }
      //debugger
      this.httpHelper.put('Common/addDonationType', this.addDonationTypeForm.value).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.router.navigateByUrl('/donation-types');
        }
        else if (this.response.statusCode == 409) {
          this.toastrService.error("Donation Type already Exist")
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
    }
  }

}
