import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor() { }
  createLocalStorageItem(name, value) {
    if (name && value) {
      localStorage.setItem(name, JSON.stringify(value));
    }
  }
  getLocalStorageItem(name) {
    if (name) {
      var result = JSON.parse(localStorage.getItem(name));
      return result;
    }
  }
  removeLocalStorageItem(name) {
    if (name) {
      localStorage.removeItem(name);
    }
  }
  setToDate(date) {
    if (date._d) {
      var dd = date._d.getDate();
      if (dd < 10) {
        dd = ('0' + dd).slice(-2);
      }
      var mm = date._d.getMonth() + 1;
      if(mm<10){
        mm=('0'+mm)
      }
      var yyyy = date._d.getFullYear();
      date = yyyy + '-' + mm + '-' + dd;
    }
    else {
      date = new Date(date)
      var dd = date.getDate();
      if (dd < 10) {
        dd = ('0' + dd).slice(-2);
      }
      var mm = date.getMonth() + 1;
      if(mm<10){
        mm=('0'+mm)
      }
      var yyyy = date.getFullYear();
      date = yyyy + '-' + mm + '-' + dd;
    }
    return (date);
  }

  setToDatePlusOneDay(date) {
    if (date._d) {
      date._d.setDate(date._d.getDate() + 1);
      var dd = date._d.getDate();
      if (dd < 10) {
        dd = ('0' + dd).slice(-2);
      }
      var mm = date._d.getMonth() + 1;
      if(mm<10){
        mm=('0'+mm)
      }
      var yyyy = date._d.getFullYear();
      date = yyyy + '-' + mm + '-' + dd;
    }
    else {
      date = new Date(date)
      var dd = date.getDate();
      date.setDate(date.getDate() + 1);

      if (dd < 10) {
        dd = ('0' + dd).slice(-2);
      }
      var mm = date.getMonth() + 1;
      if(mm<10){
        mm=('0'+mm)
      }
      var yyyy = date.getFullYear();
      date = yyyy + '-' + mm + '-' + dd;
    }
    return (date);
  }

  setToDateMinusOneDay(date) {
    if (date._d) {
      date._d.setDate(date._d.getDate() - 1);
      var dd = date._d.getDate();
      if (dd < 10) {
        dd = ('0' + dd).slice(-2);
      }
      var mm = date._d.getMonth() + 1;
      if(mm<10){
        mm=('0'+mm)
      }
      var yyyy = date._d.getFullYear();
      date = yyyy + '-' + mm + '-' + dd;
    }
    else {
      date = new Date(date)
      var dd = date.getDate();
      date.setDate(date.getDate() + 1);

      if (dd < 10) {
        dd = ('0' + dd).slice(-2);
      }
      var mm = date.getMonth() + 1;
      if(mm<10){
        mm=('0'+mm)
      }
      var yyyy = date.getFullYear();
      date = yyyy + '-' + mm + '-' + dd;
    }
    return (date);
  }
}
