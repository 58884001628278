import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from 'src/app/helpers/common/base.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {
  @Input() formName: FormGroup;
  @Input() fieldName: any;
  getFieldName;

  // used to decide the validation messages language

  currentLang: string;
  constructor(public translate: TranslateService,public baseService: BaseService,
    public formBuilder: FormBuilder,
    public validationMessages: ValidationMessagesService) {
      
      // on default languge change, change the current language for validation messages
      translate.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
        this.changeValidationMessageLang();
      });
     }

    

  ngOnInit() {
    this.changeValidationMessageLang();
  }

  /**
   * Change the current language of validation messages
   */
  changeValidationMessageLang(){
    this.currentLang = this.baseService.getLocalStorageItem('lang');
  }

  ngOnChanges() {
    this.formName;
    this.getFieldName = this.validationMessages.validation_messages[this.fieldName];
  }
}
