import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { MatPaginator, MatSort, TooltipPosition, MatTableDataSource } from '@angular/material';
import { FormControl } from '@angular/forms';
export interface UserData {
  NameEn: string;
  NameAr: string;
  CountryNameEn: string;
  CountryNameAr: string;
  Edit: string;
}
@Component({
  selector: 'app-charities',
  templateUrl: './charities.component.html',
  styleUrls: ['./charities.component.scss']
})
export class CharitiesComponent implements OnInit {
  response: any;
  responseDelete:any;
  charities: any;
  countryid: number = 3;
  displayedColumns: string[] = ['NameEn', 'NameAr', 'CountryNameEn', 'CountryNameAr', 'Edit'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);
  constructor(public globalMethodsService: GlobalMethodsService,
    private route: ActivatedRoute,
    public httpHelper: HttpClientService) {
    window.scroll(0, 0);

    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      this.charities = this.response.result;
      this.dataSource = new MatTableDataSource(this.charities);
    }
    else {
      this.charities = [];
      this.dataSource = new MatTableDataSource(this.charities);
    }
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.globalMethodsService.loader.stopAll();
  }
  getCountryName(countryCode, type) {
    if (type == "en") {
      return this.globalMethodsService.countries.find(res => res.countryCode == countryCode).nameEn;
    }
    else {
      return this.globalMethodsService.countries.find(res => res.countryCode == countryCode).nameAr;
    }
  }
  countrySelect(ev) {
    this.countryid = +ev.currentTarget.value;
    let countryCode = 'A';
    if (this.countryid != 0) {
      countryCode = this.globalMethodsService.countries.find(res => res.id == this.countryid).countryCode;
    }
    this.globalMethodsService.loader.startBackground();
    let data = {
      countryCode: countryCode,
      active:'A'
    }
    this.httpHelper.put('Common/getCharities', data).then(result => {
      this.response = result;

      if (this.response.result.length > 0) {
        this.charities = this.response.result;
        this.dataSource = new MatTableDataSource(this.charities);
        this.globalMethodsService.loader.stopAll();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
      }
      else {
        this.charities = [];
        this.dataSource = new MatTableDataSource(this.charities);
        this.globalMethodsService.loader.stopAll();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

      // this.dwsTransactions = this.byDriver;

    })


  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onChange(id, ev) {
    //debugger
    let charityActive = {
      "Id": id,
      "isActive": ev.checked,
    }

    this.globalMethodsService.loader.startBackground();
    this.httpHelper.put('Common/ActiveMasterData', charityActive).then(result => {
      this.globalMethodsService.loader.stopBackground();
      this.responseDelete = result;
      if (this.responseDelete.statusCode == 200) {
        this.globalMethodsService.loader.stopBackground();
      }
      else {
        this.globalMethodsService.loader.stopBackground();
        this.globalMethodsService.showErrorToast();
      }
    });
  }


}
