import { Component, OnInit } from '@angular/core';
import { InteractionMode } from "igniteui-angular";
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { phoneNumberValidator, emailValidator, matchingPasswords } from 'src/app/helpers/Validators/app-validators';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  public mode: InteractionMode = InteractionMode.DropDown;
  public format: string = "hh:mm tt";
  public date: Date = new Date(2018, 10, 27, 17, 45, 0, 0);
  response: any;
  cities: any;
  pageHeading: any = 'Add New User';
  addUserForm: FormGroup;
  selectedCities: any;
  countryId: number;
  userDetail: any;
  countryCode: string = '';
  roleId: Number = 2;
  charities: any = [];
  cityDisabled: boolean = true;
  constructor(public globalMethodsService: GlobalMethodsService,
    public route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder, public router: Router, public validationMessages: ValidationMessagesService, ) {
    window.scroll(0, 0);
    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {
      if (this.route.url['value'][0].path == 'edit-user') {
        this.pageHeading = 'Edit User';
        let userId = this.route.url['value'][1].path;
        this.getuserDetail(userId);
      }
      else { this.pageHeading = 'Add New User'; }
      this.cities = this.response.result;
    }
    else { this.cities = []; }
  }
  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      'userName': ['', Validators.compose([Validators.required])],
      'email': ['', Validators.compose([Validators.required, emailValidator])],
      'mobile': ['', Validators.compose([Validators.required, phoneNumberValidator])],
      'fullName': ['', Validators.compose([Validators.required])],
      'password': ['', Validators.compose([Validators.required])],
      'roleId': [this.roleId],
      'Id': [''],
      'confirmPassword': ['', Validators.compose([Validators.required])],
      'city': [],
      'charityId': [''],
      'country': ['', Validators.compose([Validators.required])]
    }, { validator: matchingPasswords('password', 'confirmPassword') });
    if (this.route.url['value'][0].path == 'edit-user') {
      this.addUserForm.controls['password']
      this.addUserForm.controls['confirmPassword']
    }
  }
  addUserSubmit() {
    this.addUserForm.controls.city.markAsTouched();
    this.addUserForm.controls.city.markAsDirty();
    if (this.addUserForm.valid) {
      this.addUserForm.value.country = +this.addUserForm.value.country;
      this.addUserForm.value.mobile = this.parseArabic(this.addUserForm.value.mobile);
      this.globalMethodsService.loader.startBackground();
      this.selectedCities = this.addUserForm.value.city;
      if(this.roleId!=2 && this.roleId!=7 && this.roleId!=8 && this.roleId!=9){
        this.addUserForm.controls.city.setValue([]);
      }
      if (this.route.url['value'][0].path == 'edit-user') {
        
        this.addUserForm.controls.country.setValue(Number(this.addUserForm.controls.country.value));
        this.httpHelper.put('User/update', this.addUserForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) { this.router.navigateByUrl('/users'); }
          else { this.globalMethodsService.showErrorToast(); }
        });
      }
      else {
        
        this.addUserForm.removeControl('Id');        
        this.addUserForm.controls.charityId.setValue(Number(this.addUserForm.controls.charityId.value));
        this.addUserForm.controls.country.setValue(Number(this.addUserForm.controls.country.value));
        this.httpHelper.put('User/add', this.addUserForm.value).then(result => {
          this.response = result;
          this.globalMethodsService.loader.stopAll();
          if (this.response.statusCode == 200) {
            this.router.navigateByUrl('/users');
          }
          else if (this.response.statusCode == 409) { this.globalMethodsService.showAlreadyExisit(); }
          else { this.globalMethodsService.showErrorToast(); }
        });
      }
    }
    else { this.validationMessages.validateAllFormFields(this.addUserForm); }
  }
  getuserDetail(id) {
    this.httpHelper.getWithAuthToken('User/' + id).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.statusCode == 200) {
        this.userDetail = this.response.result;
        this.countryCode = this.globalMethodsService.getcountryCode(this.userDetail.countryId);
        this.getcharities(this.countryCode);
        this.roleId = this.userDetail.roleId;
        this.setFormData();
      }
    });
  }
  setFormData() {
    this.cityDisabled = false;
    this.countryId = this.userDetail.countryId;
    this.getCities();
    this.addUserForm.controls.confirmPassword.clearValidators();
    this.addUserForm.controls.confirmPassword.updateValueAndValidity()
    this.addUserForm.controls['password'].setValue('');
    this.addUserForm.controls.password.clearValidators();
    this.addUserForm.controls.password.updateValueAndValidity()
    if (this.roleId == 2 || this.roleId==7 || this.roleId==8 || this.roleId==9) {
      this.addUserForm.controls.charityId.clearValidators();
      this.addUserForm.controls.charityId.updateValueAndValidity();
      this.addUserForm.controls.city.setValidators(Validators.required);
      this.addUserForm.controls.city.updateValueAndValidity();
    }
    else {
      this.addUserForm.controls.charityId.clearValidators();
      this.addUserForm.controls.city.clearValidators();
      this.addUserForm.controls.city.updateValueAndValidity();
      this.addUserForm.controls.charityId.setValidators(Validators.required);
      this.addUserForm.controls.charityId.updateValueAndValidity();
    }

    this.addUserForm.controls['userName'].setValue(this.userDetail.userName);
    this.addUserForm.controls['country'].setValue(this.userDetail.countryId);
    this.addUserForm.controls['fullName'].setValue(this.userDetail.fullName);
    this.addUserForm.controls['email'].setValue(this.userDetail.email);
    this.addUserForm.controls['mobile'].setValue(this.userDetail.mobile);
    this.addUserForm.controls['Id'].setValue(this.userDetail.id);
    this.addUserForm.controls['city'].setValue(this.userDetail.city.map(res => res.cityId));
    this.addUserForm.controls['charityId'].setValue(this.userDetail.charityId);
    this.addUserForm.controls['roleId'].setValue(this.userDetail.roleId);
  }
  parseArabic(str) {
    return String(str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
      return d.charCodeAt(0) - 1632; // Convert Arabic numbers
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
      return d.charCodeAt(0) - 1776; // Convert Persian numbers
    }));
  }
  countrySelect(ev) {
    this.countryId = +ev.currentTarget.value;
    this.countryCode = this.globalMethodsService.getcountryCode(this.countryId);
    this.getcharities(this.countryCode);
    this.getCities();
  }
  userTypeSelect(ev) {
    this.roleId = +ev.currentTarget.value;
    this.addUserForm.controls['roleId'].setValue(this.roleId);
    if (this.roleId == 2 || this.roleId==7 ||  this.roleId==8 || this.roleId==9) {
      this.addUserForm.controls.charityId.clearValidators();
      this.addUserForm.controls.charityId.updateValueAndValidity();
      this.addUserForm.controls.city.setValidators(Validators.required);
      this.addUserForm.controls.city.updateValueAndValidity();
    }
    else {
      this.addUserForm.controls.city.clearValidators();
      this.addUserForm.controls.city.updateValueAndValidity();
      this.addUserForm.controls.charityId.setValidators(Validators.required);
      this.addUserForm.controls.charityId.updateValueAndValidity();
    }
  }
  getCities() {
    this.httpHelper.getWithAuthToken('Common/getCities?countryId=' + this.countryId).then(result => {
      this.response = result;
      if (this.response.result.length > 0) {
        this.cities = this.response.result;
        this.cityDisabled = false;
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }
      else {
        this.cities = [];
        this.cityDisabled = true;
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }
    })
  }
  getcharities(countryCode) {
    this.globalMethodsService.loader.startBackground();
    let data = {
      countryCode: countryCode
    }
    this.httpHelper.put('Common/getCharities', data).then(result => {
      this.response = result;
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
      }
      else { this.charities = []; }
    })
  }
  checkPassword(event) {
    
    if(this.route.url['value'][0].path == 'edit-user' && event.target.value!=''){
        this.addUserForm.controls.confirmPassword.setValidators(Validators.required);
      this.addUserForm.controls['confirmPassword'].updateValueAndValidity();
    }
    else if(this.route.url['value'][0].path == 'edit-user' && event.target.value==''){
      this.addUserForm.controls.confirmPassword.clearValidators();
      this.addUserForm.controls['confirmPassword'].updateValueAndValidity();
    }
  }
}