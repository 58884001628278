import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { InteractionMode } from "igniteui-angular";
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Observable } from 'rxjs';
import { map, isEmpty } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/helpers/common/base.service';
import { stringify } from 'querystring';
import { Lightbox } from 'ngx-lightbox';
import LocationPicker from "location-picker";
import { MatTableDataSource, TooltipPosition } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export interface CheckReport {
  reportId: number;
  reporterName: string;
  reportTime: string;
  fullnessLevel: number;
  notes: string;
  picture: string;
}

export interface MReport {
  reportId: number;
  reporterName: string;
  reportTime: string;
  jobDescription: string;
}

export interface Supervisor {
  fullName: string;
  username: string;
}

export interface DonationBoxProductivity {
  monthYear: string;
  average:number;
}


@Component({
  selector: 'app-donation-box-details',
  templateUrl: './donation-box-details.component.html',
  styleUrls: ['./donation-box-details.component.scss']
})



export class DonationBoxDetailsComponent implements OnInit {
  response: any;
  creports: any = [];
  mreports: any = [];
  supervisors: any = [];
  donationBoxDetails: any;
  lp: LocationPicker;
  myAngularxQrCode: string = null;
  expendMenu: boolean = false;
  displayedColumns: string[] = ['reportId', 'reporterName', 'reportTime', 'fullnessLevel', 'notes','reporterLocation','firstAttachment' ,'secondAttachment','thirdAttachment'];
  mDisplayedColumns: string[] = ['reportId', 'reporterName', 'reportTime', 'jobDescription'];
  pDisplayedColumns: string[] = ['monthYear', 'average'];

  supervisorsColumns: string[] = ['fullName', 'username'];
  checkReports: MatTableDataSource<CheckReport>;
  maintenanceReports: MatTableDataSource<MReport>;
  donationBoxProductivityReports: MatTableDataSource<DonationBoxProductivity>;
  supervisorsDataSource: MatTableDataSource<Supervisor>;
  cReports: any;
  mReports: any;
  donationBoxProductiviy: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  positionOptions: TooltipPosition[] = ['above'];
  position = new FormControl(this.positionOptions[0]);

  constructor(public httpService: HttpClientService,
    public globalMethodsService: GlobalMethodsService,
    public validations: ValidationMessagesService,
    public globalService: GlobalMethodsService,
    public router: Router,
    public appComponent: AppComponent,
    private route: ActivatedRoute, public formBuilder: FormBuilder, public toastrService: ToastrService,
    public baseService: BaseService, public el: ElementRef,
    public _lightbox: Lightbox,
    public httpHelper: HttpClientService) {

    window.scroll(0, 0);


    this.supervisorsDataSource = new MatTableDataSource(this.supervisors);

  }
  ngOnInit() {
    this.response = this.route.snapshot.data.data;
    this.donationBoxDetails = this.response.result;
    this.myAngularxQrCode = 'http://alkhaireya.net/#/fill-donation-box-check-report/' + this.donationBoxDetails[0].id;

    let location = this.donationBoxDetails[0].location.split(",");
    this.lp = new LocationPicker('map', {
      lat: location[0], lng: location[1]
    });

    this.getCReports();
    this.getMReports();
    this.getDonationBoxProductivity();

    //this.globalMethodsService.loader.stopAll();
  }

  getCReports() {
    this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxCheckReportsByDonationBoxId?id=' + this.donationBoxDetails[0].id).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();

      if (this.response.statusCode == 200) {
        this.cReports = this.response.result;
      }
      else {
        this.cReports = [];
      }
      this.checkReports = new MatTableDataSource(this.cReports);
      this.checkReports.paginator = this.paginator;
      this.checkReports.sort = this.sort;
    });
  }

  getMReports() {
    this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxMaintenanceReportsByDonationBoxId?id=' + this.donationBoxDetails[0].id).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();

      if (this.response.statusCode == 200) {
        this.mReports = this.response.result;
      }
      else {
        this.cReports = [];
      }
      this.maintenanceReports = new MatTableDataSource(this.mReports);
      this.maintenanceReports.paginator = this.paginator;
      this.maintenanceReports.sort = this.sort;
    });  }

    getDonationBoxProductivity() {
      this.httpHelper.getWithAuthToken('DonationBoxes/getDonationBoxProductivity?donationBoxId=' + this.donationBoxDetails[0].id).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
  
        if (this.response.statusCode == 200) {
          this.donationBoxProductiviy = this.response.result;
        }
        else {
          this.donationBoxProductiviy = [];
        }
        this.donationBoxProductivityReports = new MatTableDataSource(this.donationBoxProductiviy);
        this.donationBoxProductivityReports.paginator = this.paginator;
        this.donationBoxProductivityReports.sort = this.sort;
      });  
    }
}
