import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import LocationPicker from "location-picker";

@Component({
  selector: 'app-add-donation-box-weight-record',
  templateUrl: './add-donation-box-weight-record.component.html',
  styleUrls: ['./add-donation-box-weight-record.component.scss']
})
export class AddDonationBoxWeightRecordComponent implements OnInit {
  response: any;
  charities: any;
  form: FormGroup;

  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    public globalService: GlobalMethodsService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    // get charities to populate the select menu
    this.getCharities();

    // prepare the form object and its validation rules
    this.form = this.formBuilder.group({
      'weight': ['', Validators.compose([Validators.required,Validators.min(0)])],
      'charityId': ['', Validators.compose([Validators.required])],
      'createdBy': []
    });
    
    this.globalMethodsService.loader.stopAll();

  }

 
  submitForm() {

    if (this.form.valid) {
      this.form.controls['createdBy'].setValue(this.baseService.getLocalStorageItem('userObj').id);

      this.form.controls['weight'].setValue(parseFloat(this.form.controls['weight'].value));
      this.globalMethodsService.loader.startBackground();

      this.httpHelper.put('DonationBoxes/addDonationBoxWeightRecord', this.form.value).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.router.navigateByUrl('/donation-box-weight-list');
        }
        else if (this.response.statusCode == 409) {
          this.toastrService.error("Box exist already")
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
      

    }
  }

  getCharities() {
    this.globalMethodsService.loader.startBackground();
    let data = {

    }
    this.httpHelper.put('Common/getDonationBoxesCharities', data).then(result => {
      this.response = result;
      if (this.response.result.length > 0) {
        this.charities = this.response.result;
      }
      else { this.charities = []; }
    })
  }

}
