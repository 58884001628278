import { Component, OnInit } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import { nameArValidator } from 'src/app/helpers/Validators/app-validators';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.scss']
})
export class AddCountryComponent implements OnInit {
  response: any;
  countryDetail: any;
  pageHeading: any = 'Add New Country';
  countryId: number;
  addCountryForm: FormGroup;
  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public route: ActivatedRoute,
    public toastrService: ToastrService) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    this.addCountryForm = this.formBuilder.group({
      'countryCode': ['', Validators.compose([Validators.required])],
      'nameEn': ['', Validators.compose([Validators.required])],
      'nameAr': ['', Validators.compose([Validators.required, nameArValidator])],
      'email': ['', Validators.compose([Validators.required])],
      'phone': ['', Validators.compose([Validators.required])],
      'id': ['']
    });
    if (this.route.url['value'][0].path == 'edit-country') {
      this.response = this.route.snapshot.data.data;
      this.pageHeading = 'Edit Country';
      if (this.response.statusCode == 200) {
        this.countryDetail = this.response.result;
        this.setFormData();
      }
      else {
        this.globalMethodsService.loader.stopAll();
      }
    }
  }
  setFormData() {
    this.countryId = this.countryDetail[0].headId;
    this.addCountryForm.controls['phone'].setValue(this.countryDetail[0].phone);
    this.addCountryForm.controls['email'].setValue(this.countryDetail[0].email);
    this.addCountryForm.controls['countryCode'].setValue(this.countryDetail[0].countryCode);
    this.addCountryForm.controls['nameEn'].setValue(this.countryDetail[0].nameEn);
    this.addCountryForm.controls['nameAr'].setValue(this.countryDetail[0].nameAr);
    this.addCountryForm.controls['id'].setValue(this.countryDetail[0].id);
    this.globalMethodsService.loader.stopAll();
  }
  addCountrySubmit() {
    if (this.addCountryForm.valid) {
      this.globalMethodsService.loader.startBackground();
      //this.addCountryForm.value.headId = +this.addCountryForm.value.headId;
      if (this.addCountryForm.value.id == "") {
        this.addCountryForm.value.id = 0;
      }
      else {
        this.addCountryForm.value.id = +this.addCountryForm.value.id
      }
      //debugger
      this.httpHelper.put('Common/addCountry', this.addCountryForm.value).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.router.navigateByUrl('/countries');
        }
        else if (this.response.statusCode == 409) {
          this.toastrService.error("Country Code Already exist")
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
    }
  }

}
