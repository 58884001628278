import { Component, HostListener, Inject  } from '@angular/core';
import { BaseService } from './helpers/common/base.service';
import { Router, ActivatedRoute,  NavigationStart, Event as NavigationEvent,NavigationEnd  } from '@angular/router';
import { fadeAnimation } from './helpers/animations/fade.animation';
import { PlatformLocation } from '@angular/common';
import { AuthGuardService } from './helpers/auth/auth-guard.service';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { GlobalMethodsService } from './helpers/global/global-methods.service';
import { UserIdleService } from 'angular-user-idle';
import { EnvService } from './helpers/environment/env.service';
import {TranslateService} from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';



export const MY_FORMATS = {
  parse: {
    dateInput: { month: 'numeric', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
  providers: [
    {
      provide: DateAdapter, useClass: MomentDateAdapter
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AppComponent {
  title = 'aunak-backoffice';
  showHeader: boolean=false;
  currentDate: any;
  tabCloseTime: Date;
  deafualtLang: "en";
  currentRoute: "";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    translate: TranslateService,
    public baseService: BaseService,
    public router: Router, public route: ActivatedRoute, public location: PlatformLocation,
    public authGuardService: AuthGuardService,
    public globalService:GlobalMethodsService,
    private userIdle: UserIdleService,
    public env: EnvService) {
      this.userIdle.setConfigValues(this.env.userIdleConfig);
      this.globalService.getCountries();
      this.globalService.getCities(0);
    this.showHeader = true;
 
    this.router.events
    .subscribe(
      (event: NavigationEvent) => {
        if(event instanceof NavigationStart) {
          if(event.url.startsWith("/fill-donation-box-check-report")){
            this.baseService.createLocalStorageItem('rUrl', event.url);
          }
          
        }
      });
 

    // get current language from the local storage
    let currentLang = this.baseService.getLocalStorageItem('lang');
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;

    // if the current language is chosen already, then set it as default language
    if(currentLang == 'ar' || currentLang == 'en'){
      translate.setDefaultLang(currentLang);
      if(currentLang == 'ar'){
        htmlTag.dir = 'rtl';
      }else{
        htmlTag.dir = 'ltr';
      }
    }else{ // if there is no language chosen already, then set the default lang to english
      translate.setDefaultLang('en');
      this.baseService.createLocalStorageItem('lang','en');
      htmlTag.dir = 'ltr';
    }

    location.onPopState(() => {
      if (window.location.hash == '#/') {
        if (this.baseService.getLocalStorageItem('userObj')) {
          this.router.navigateByUrl('/dashboard');
        }
      }
    });
    //console.log(this.route);
  }
  
  ngOnInit() {
    this.currentDate = new Date();
    if (this.baseService.getLocalStorageItem('tabCloseTime')) {
      this.tabCloseTime = new Date(JSON.parse(this.baseService.getLocalStorageItem('tabCloseTime')));
      //console.log(this.tabCloseTime);
      if (this.currentDate.getDate() > this.tabCloseTime.getDate() || this.currentDate.getMonth() > this.tabCloseTime.getMonth() || this.currentDate.getFullYear() > this.tabCloseTime.getFullYear()) {
        if (this.baseService.getLocalStorageItem('userObj')) {
          this.globalService.logout();
        }
      }
      else if (this.currentDate.getHours() - this.tabCloseTime.getHours() >= 1) {
        if (this.baseService.getLocalStorageItem('userObj')) {
          this.globalService.logout();
        }
      }
      else if ((this.currentDate.getMinutes() - this.tabCloseTime.getMinutes()) * 60 >= this.env.userIdleConfig.timeout && this.currentDate.getHours() - this.tabCloseTime.getHours()==0) {
        if (this.baseService.getLocalStorageItem('userObj')) {
          this.globalService.logout();
        }
      }
    }
    if (window.location.hash != '#/') {
      this.userIdle.startWatching();
      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe();
      this.userIdle.onTimeout().subscribe(() => this.globalService.logout());
    }
  }
  public getRouterOutletState(outlet) {
   
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  onActivate(ev) {
    window.scroll(0, 0);
  }
  @HostListener('window:mousemove') refreshUserState() {
    this.userIdle.stopTimer();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  }
  @HostListener('window:click') refreshUserStateclick() {
    this.userIdle.stopTimer();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  }
  @HostListener('window:wheel') refreshUserStatewheel() {
    this.userIdle.stopTimer();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  }
  @HostListener('window:dblclick') refreshUserStatedbdlclick() {
    this.userIdle.stopTimer();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  }
  @HostListener('window:drag') refreshUserStatedrag() {
    this.userIdle.stopTimer();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  }
  @HostListener('window:keydown') refreshUserStatekey() {
    this.userIdle.stopTimer();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  }
  @HostListener('window:unload') tabClosed() {
    var closedTime = new Date();
    this.baseService.createLocalStorageItem("tabCloseTime", JSON.stringify(closedTime));
  }
}
