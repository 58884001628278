import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { BaseService } from '../common/base.service';
import { Router } from '@angular/router';
import { EnvService } from '../environment/env.service';

let baseURL = '';//environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  messages: Observable<any[]>;
  constructor(private http: HttpClient, public baseService: BaseService, public router: Router, private env: EnvService) {
    baseURL = this.env.apiUrl;
  }
  public getObservable(type): Observable<any> {
    return this.http.get<any>(baseURL + type);
  }
  public getObservableParams(type, params): Observable<any> {
    return this.http.get<any>(baseURL + type, params);
  }
  get(host) {
    return this.http.get(baseURL + host).pipe(map(res => {
      return res;
    }));
  }
  getWithAuthToken(host) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({
        'Authorization': "Bearer " + localStorage.getItem('Token'),
        'content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      });
      this.http.get(baseURL + host, { headers: headers })
        .subscribe(res => {

          resolve(res);
        }, (err) => {

          if (err.status == 401) {
            this.Logout();
          }
          reject(err);
        });
    });
  }
  getWithAuthTokenReq(host, data) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({
        'Authorization': "Bearer " + localStorage.getItem('Token'),
        'content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0'
      });
      this.http.get(baseURL + host, { observe: data, headers: headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {

          if (err.status == 401) {
            this.Logout();
          }
          reject(err);
        });
    });
  }
  getWithAuthToken2(host): Observable<any> {
    let headers = new HttpHeaders({
      'Authorization': "Bearer " + localStorage.getItem('Token'),
      'content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.get(baseURL + host, { headers: headers });
  }
  post(host, data) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers.set('Content-Type', 'application/json;');
      this.http.post(baseURL + host, data, { headers: headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          resolve(err);
        });
    });
  }
  postWithAuthToken(host, data) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({
        'Authorization': "Bearer " + localStorage.getItem('Token'),
        'content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        'Pragma': 'no-cache',
        'Expires': '0',
      });
      this.http.post(baseURL + host, data, { headers: headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (err.status == 401) {
            this.Logout();
          }
          resolve(err);
        });
    });
  }
  postWithAuthToken2(host, data): Observable<any> {
    let headers = new HttpHeaders({
      'Authorization': "Bearer " + localStorage.getItem('Token'),
      'content-Type': 'application/json',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
    return this.http.post(baseURL + host, data, { headers: headers });
  }
  getById(host) {
    return this.http.get(baseURL + host).pipe(map(res => {
      return res;
    }));
  }
  put(host, data) {
    return new Promise((resolve, reject) => {
      this.http.put(baseURL + host, data).subscribe(res => {
        resolve(res);
      }, (err) => {
        resolve(err);
      });
    });
  }
  delete(host) {
    return this.http.delete(baseURL + host).pipe(map(res => {
      return res;
    }));
  }
  Logout() {
    localStorage.clear();
    this.router.navigateByUrl('/');
  }
}