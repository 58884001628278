import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/helpers/common/base.service';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthGuardService } from 'src/app/helpers/auth/auth-guard.service';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { ToastrService } from 'ngx-toastr';
import { nameArValidator } from 'src/app/helpers/Validators/app-validators';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EnvService } from 'src/app/helpers/environment/env.service';
let baseURL = 'https://localhost:44322/api/';
@Component({
  selector: 'app-add-new-request',
  templateUrl: './add-new-request.component.html',
  styleUrls: ['./add-new-request.component.scss']
})
export class AddNewRequestComponent implements OnInit {
  url = '';
  addCharityForm: FormGroup;
  response: any;
  charityDetail: any;
  pageHeading: any = 'Add New Charity';
  countryId: number;
  cityDisabled: boolean = true;
  cities: any;
  fileName: string = 'Choose File';
  public progress: string;
  constructor(public formBuilder: FormBuilder,
    public baseService: BaseService,
    public httpHelper: HttpClientService,
    public router: Router,
    public authGuardService: AuthGuardService,
    public globalMethodsService: GlobalMethodsService,
    public validationMessages: ValidationMessagesService,
    public route: ActivatedRoute,
    public toastrService: ToastrService,
    private http: HttpClient,
    private env: EnvService) {
    baseURL = this.env.apiUrl;
    window.scroll(0, 0);
  }

  ngOnInit() {
    this.addCharityForm = this.formBuilder.group({
      'nameEn': ['', Validators.compose([Validators.required])],
      'nameAr': ['', Validators.compose([Validators.required, nameArValidator])],
      'id': [''],
      'headId': ['', Validators.compose([Validators.required])],
      'cityId': ['', Validators.compose([Validators.required])],
      'attachmentId': [''],
      'website': ['',Validators.compose([Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)])],
      'phone': [''],
      'descriptionEn': ['', Validators.compose([Validators.maxLength(4000)])],
      'descriptionAr': ['', Validators.compose([nameArValidator, Validators.maxLength(4000)])],
      'charityLogo': ['']
    });
    if (this.route.url['value'][0].path == 'edit-charity') {
      this.response = this.route.snapshot.data.data;
      this.pageHeading = 'Edit Charity';
      if (this.response.statusCode == 200) {
        
        //  this.charityDetail = this.response.result;
        let charityId = this.route.url['value'][1].path;
        this.getCharityDetail(charityId);
        //this.setFormData();
      }
      else {
        this.globalMethodsService.loader.stopAll();
      }
    }
  }
  setFormData() {
    //debugger
    this.countryId = this.charityDetail[0].headId;
    this.getCities();
    this.cityDisabled = false;
    this.addCharityForm.controls['nameEn'].setValue(this.charityDetail[0].nameEn);
    this.addCharityForm.controls['nameAr'].setValue(this.charityDetail[0].nameAr);
    this.addCharityForm.controls['headId'].setValue(this.charityDetail[0].headId);
    this.addCharityForm.controls['id'].setValue(this.charityDetail[0].id);
    this.addCharityForm.controls['cityId'].setValue(this.charityDetail[0].cityId);
    this.addCharityForm.controls['attachmentId'].setValue(this.charityDetail[0].attachmentId);
    this.addCharityForm.controls['website'].setValue(this.charityDetail[0].website);
    this.addCharityForm.controls['phone'].setValue(this.charityDetail[0].phone);
    this.addCharityForm.controls['descriptionEn'].setValue(this.charityDetail[0].descriptionEn);
    this.addCharityForm.controls['descriptionAr'].setValue(this.charityDetail[0].descriptionAr);
    if (this.charityDetail[0].attachmentName != null) {
      // let fileName = this.charityDetail[0].attachmentName.slice(this.charityDetail[0].attachmentName.lastIndexOf("/") + 1);
      this.fileName = this.charityDetail[0].attachmentName;
      // this.addCharityForm.controls['charityLogo'].setValue(fileName);
    }
    else if (this.charityDetail[0].attachmentUrl != null && this.charityDetail[0].attachmentName == null) {
      this.fileName = this.charityDetail[0].attachmentUrl.slice(this.charityDetail[0].attachmentUrl.lastIndexOf("/") + 1);
    }
    if (this.charityDetail[0].attachmentUrl != null) {
      this.url = this.charityDetail[0].attachmentUrl;
    }
    this.globalMethodsService.loader.stopAll();
  }
  countrySelect(ev) {

    this.countryId = +ev.currentTarget.value;
    this.getCities();
  }
  getCities() {
    this.globalMethodsService.loader.startBackground();
    this.httpHelper.getWithAuthToken('Common/getCities?countryId=' + this.countryId).then(result => {
      this.response = result;

      if (this.response.result.length > 0) {
        this.cities = this.response.result;
        this.cityDisabled = false;
        //console.log(this.cities);
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }
      else {
        this.cities = [];
        this.cityDisabled = true;
        this.globalMethodsService.loader.stopAll();
        return this.cities;
      }

    })
  }
  getCharityDetail(id) {
    this.httpHelper.getWithAuthToken('Common/getCharity?charityId=' + id).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.statusCode == 200) {
        this.charityDetail = this.response.result;
        this.setFormData();
      }

    });
  }
  addCharitySubmit() {
    
    this.addCharityForm.markAllAsTouched();
    if (this.addCharityForm.valid) {
   
      this.globalMethodsService.loader.startBackground();
      this.addCharityForm.controls.attachmentId.setValue(+this.addCharityForm.controls.attachmentId.value);
      this.addCharityForm.value.headId = +this.addCharityForm.value.headId;
      if (this.addCharityForm.value.id == "") {
        this.addCharityForm.value.id = 0;
      }
      else {
        this.addCharityForm.value.id = +this.addCharityForm.value.id
      }
      this.addCharityForm.value.cityId = +this.addCharityForm.value.cityId
      
      this.httpHelper.put('Common/addCharity', this.addCharityForm.value).then(result => {
        this.response = result;
        this.globalMethodsService.loader.stopAll();
        if (this.response.statusCode == 200) {
          this.router.navigateByUrl('/charities');
        }
        else if (this.response.statusCode == 409) {
          this.toastrService.error("Charity Name Already exist")
        }
        else {
          this.globalMethodsService.showErrorToast();
        }
      });
    }
  }


  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      let fileToUpload = <File>event.target.files[0];
      
      if (fileToUpload.type.split('/')[0].toLowerCase() != 'image') {
        this.addCharityForm.controls.charityLogo.setErrors({ 'invalidType': true });
      }
      else {
        this.fileName=fileToUpload.name;
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        this.http.put(baseURL + 'Common/uploadFile', formData, { reportProgress: true, observe: 'events' }).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = String(Math.round(100 * event.loaded / event.total)) + '%';
          else if (event.type === HttpEventType.Response) {
            this.response = event.body;
            if (this.response.statusCode == 200) {
              //debugger
              this.url = this.response.url;
              this.progress = '';
              this.addCharityForm.controls['attachmentId'].setValue(this.response.attachmentId);
            }
            else {
              this.globalMethodsService.showErrorToast();
              this.addCharityForm.controls['attachmentId'].setValue('');
            }
          }
        });
      }
    }
  }
}
