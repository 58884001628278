import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalMethodsService } from 'src/app/helpers/global/global-methods.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClientService } from 'src/app/helpers/http/http-client.service';
import { TooltipPosition } from '@angular/material';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { dateLessThan } from 'src/app/helpers/Validators/app-validators';
import { ValidationMessagesService } from 'src/app/helpers/Validators/validation-messages.service';
import { BaseService } from 'src/app/helpers/common/base.service';

export interface UserData {
  driver: string;
  mobileNo: string;
  vehicleNo: string;
  city: string;
  country: string;
  active: string;
}

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {
  drivers: any;
  response: any;
  responseDelete: any;
  displayedColumns: string[] = ['driver', 'mobileNo', 'vehicleNo', 'country', 'city', 'active'];
  dataSource: MatTableDataSource<UserData>;
 
  
  filterByDate: FormGroup;
  countryAllSelected: boolean = true;
  countryid = 0;
  startDate = new Date();
  endDate = new Date();
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  positionOptions: TooltipPosition[] = ['above']; //'after', 'before', 'above', 'below', 'left', 'right'
  position = new FormControl(this.positionOptions[0]);

  constructor(public globalMethodsService: GlobalMethodsService,
    public baseService: BaseService,
    public route: ActivatedRoute,
    public httpHelper: HttpClientService,
    public formBuilder: FormBuilder, public validationMessages: ValidationMessagesService, ) {

    window.scroll(0, 0);
    this.startDate = this.globalMethodsService.setFromDate(this.startDate, -3);
    
    if (this.baseService.getLocalStorageItem('userObj').role == 'admin-country') {

      this.countryid = 1;

    }
    // Create 100 users
    this.globalMethodsService.loader.stopAll();
    this.response = this.route.snapshot.data.data;
    if (this.response.statusCode == 200) {

      this.drivers = this.response.result;

    }
    else if (this.response.length > 0) {
      this.drivers = this.response
    }
    else { this.drivers = []; }
    // Assign the data to the data source for the table to render
  
      this.dataSource = new MatTableDataSource(this.drivers);
  }
  ngOnInit() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onChange(Id, ev) {
    //debugger
    let userActive = {
      "Id": Id,
      "isActive": ev.checked,
    }

    this.globalMethodsService.loader.startBackground();
    this.httpHelper.put('Drivers/update', userActive).then(result => {
      this.globalMethodsService.loader.stopBackground();
      this.responseDelete = result;
      if (this.responseDelete.statusCode == 200) {
        this.globalMethodsService.loader.stopBackground();
      }
      else {
        this.globalMethodsService.loader.stopBackground();
        this.globalMethodsService.showErrorToast();
      }
    });
  }
  createString(arr, key) {
    //debugger
    return arr.map(function (obj) {
      return "" + obj[key] + "";
    }).join(', ');
  }
 
  countrySelect(ev) {
    this.countryid = +ev.currentTarget.value;
    if (this.countryid != 0) {
      this.countryAllSelected = false;
        this.getDrivers();
      //  this.filterByDate();
    }
    else {
      this.countryAllSelected = true;
      //this.filterByDate();
    }
  }
  getDrivers() {
    this.globalMethodsService.loader.startBackground();
    this.httpHelper.getWithAuthToken('Drivers?countryId=' + this.countryid).then(result => {
      this.response = result;
      this.globalMethodsService.loader.stopAll();
      if (this.response.result.length > 0) {
        this.drivers = this.response.result;
        this.dataSource = new MatTableDataSource(this.drivers);
       
      }
      else {
        this.drivers = [];
        this.dataSource = new MatTableDataSource(this.drivers);
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // this.dwsTransactions = this.byDriver;
    });
  }
}
